<template>
  <div class="col-12">
    <chart-card title="Survivors Age Group Stacked Bar Chart"
                subTitle="Survivors age group in group"
                v-if="!isFetchingSurvivorAgeGroupBar"
                :chart-data="data"
                :chart-options="options"
                chart-type="Bar"
                footer-text='Last Two weeks (Default)'
    >
      <div v-if="!data || data.series.length === 0" slot="empty">No Data Available</div>
      <div class="row" slot="filter">
        <div class="col-6">
          <div class="dropdown menu">
            <button class="btn btn-round btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Filter
            </button>
            <div class="dropdown-menu dropdown-menu-left p4">
              <form class="col-12" @submit.prevent>
                <date-range-picker
                  v-model="dateRange"
                  ref="picker"
                  :minDate="minDate"
                  :maxDate="maxDate"
                  opens="right"
                  :locale-data="locale"
                  @update="updateValues"
                >
                  <template v-slot:input="picker" style="min-width: 50px;">
                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                  </template>
                </date-range-picker>
                <br>
              </form>
            </div>
          </div>
        </div>
      </div>
    </chart-card>
  </div>
</template>
<script>
import {
  mapState,
  mapActions,
} from 'vuex';
import moment from 'moment';
import Chartist from 'chartist';
require('chartist-plugin-legend');
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
import { ChartCard } from "@/components/index";
import { getCounsellor } from '@/utils/auth'
import {
  minDate,
  nowDate,
  twoWeekStartDate,
  twoWeekEndDate,
  isOneDay,
  isMoreThanAMonth
} from "@/utils/utils";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
  components: {
    ChartCard,
    DateRangePicker
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      counsellor: null,
      minDate: minDate(),
      maxDate: nowDate(),
      dateRange: {
        startDate: twoWeekStartDate(),
        endDate: twoWeekEndDate()
      },
      locale: {
        direction: 'ltr',
        format: 'mm-dd-yyyy',
        separator: ' to ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1
      },
      config: {
        format: '',
        useCurrent: false,
        showClear: true,
        showClose: true,
      },
      legendNames: [],
      filter: {
        workspaceId: null,
        start_absolute: twoWeekStartDate(),
        end_absolute: twoWeekEndDate(),
      },
      countLineData: null,
      data: null,
      options: null,
    };
  },
  filters: {
    date(val) {
      return val ? moment(val).format('LL') : "";
    }
  },
  computed: {
    ...mapState('analytics', ['survivorAgeGroupBar', 'isFetchingSurvivorAgeGroupBar']),
    ...mapState("auth", ["counsellorList", "isFetchingCounsellorList"]),
  },
  watch: {
    async survivorAgeGroupBar(newValue) {
      await this.formatGraphData(newValue);
    }
  },
  async mounted() {

  },
  async created() {
    this.counsellor = getCounsellor();
    await this.fetchWithFilter()
  },
  methods: {
    ...mapActions('analytics', [
      'fetchSurvivorAgeGroupBar',
    ]),
    updateValues({ startDate, endDate }) {
      this.filter.start_absolute = startDate;
      this.filter.end_absolute = endDate;
      this.fetchWithFilter();
    },
    async fetchWithFilter() {
      this.filter.workspaceId = this.counsellor.workspaceId;

      const params = this.filter;
      Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);

      if (isOneDay(this.filter.start_absolute, this.filter.end_absolute)) {
        const stateDate = moment(this.filter.start_absolute)
        const endDate = moment(this.filter.end_absolute)
        this.filter.start_absolute = stateDate.startOf('day').valueOf()
        this.filter.end_absolute = endDate.endOf('day').valueOf()
      }
      else if (isMoreThanAMonth(this.filter.start_absolute, this.filter.end_absolute)) {
        const stateDate = moment(this.filter.start_absolute)
        const endDate = moment(this.filter.end_absolute)
        this.filter.start_absolute = stateDate.startOf('month').valueOf()
        this.filter.end_absolute = endDate.endOf('month').valueOf()
      }
      else {
        const stateDate = moment(this.filter.start_absolute)
        const endDate = moment(this.filter.end_absolute)
        this.filter.start_absolute = stateDate.startOf('day').valueOf()
        this.filter.end_absolute = endDate.endOf('day').valueOf()
      }

      // console.log("PARAMS", JSON.stringify(params))
      await this.fetchSurvivorAgeGroupBar(params);
    },
    removeDuplicates(arr) {
      return [...new Set(arr)];
    },
    getExpectedTimeStamps (start, end) {
      const expectedTimeStamps = [];
      const startDate          = moment(start);
      const endDate            = moment(end);
      if (isOneDay(this.filter.start_absolute, this.filter.end_absolute)) {
        while (startDate.isBefore(endDate)) {
          expectedTimeStamps.push(startDate.format('h:mm a'));
          startDate.add(1, 'hours');
        }
      }
      else if (isMoreThanAMonth(this.filter.start_absolute, this.filter.end_absolute)) {
        while (startDate.isBefore(endDate)) {
          expectedTimeStamps.push(startDate.format('MMMM YYYY'));
          startDate.add(1, 'months');
        }
      }
      else {
        while (startDate.isBefore(endDate)) {
          expectedTimeStamps.push(startDate.format('YYYY-MM-DD'));
          startDate.add(1, 'days');
        }
      }
      return expectedTimeStamps;
    },
    async formatGraphData() {
      this.data = {
        labels: [],
        series: []
      }
      this.legendNames = []
      this.options = {
        stackBars: true,
        height: "350px",
        axisY: {
          onlyInteger: true
        },
        low: 0,
        plugins: [
          Chartist.plugins.tooltip(),
          ChartistTooltip(),
          Chartist.plugins.legend({
            position: 'bottom',
            legendNames: this.legendNames,
          })
        ]
      }
      const expectedTimeStamps = this.getExpectedTimeStamps(
        this.filter.start_absolute,
        this.filter.end_absolute
      );
      expectedTimeStamps.forEach((timestamp, idx) => {
        if (!this.data.labels.includes(timestamp)) this.data.labels.push(timestamp);
      });
      //console.log("SURVIVOR AGE GROUP BAR", JSON.stringify(this.survivorAgeGroupBar))
      this.survivorAgeGroupBar.forEach(query => {

        const resultTimestamps = this.removeDuplicates(query.entries.map(entry => {
          if (isOneDay(this.filter.start_absolute, this.filter.end_absolute)) {
            return moment(entry.created).startOf('hour').format('h:mm a')
          }
          else if (isMoreThanAMonth(this.filter.start_absolute, this.filter.end_absolute)) {
            return moment(entry.created).format('MMMM YYYY')
          }
          else {
            return moment(entry.created).format('YYYY-MM-DD')
          }
        }));

        this.legendNames.push(query.ageGroup);

        const groupCountByAge = query.entries.reduce((group, entry) => {
          if (isOneDay(this.filter.start_absolute, this.filter.end_absolute)) {
            const timestamp = moment(entry.created).startOf('hour').format('h:mm a');
            const count = group[timestamp] || 0;
            group[timestamp] = count + 1;
            return group;
          }
          else if (isMoreThanAMonth(this.filter.start_absolute, this.filter.end_absolute)) {
            const timestamp = moment(entry.created).format('MMMM YYYY');
            const count = group[timestamp] || 0;
            group[timestamp] = count + 1;
            return group;
          }
          else {
            const timestamp = moment(entry.created).format('YYYY-MM-DD');
            const count = group[timestamp] || 0;
            group[timestamp] = count + 1;
            return group;
          }
        }, {});

        // console.log("RESULT MAP ", JSON.stringify(groupCountByAge))

        const elements = [];
        expectedTimeStamps.forEach((timestamp, idx) => {
          if (resultTimestamps.includes(timestamp)) {
            elements.push(groupCountByAge[timestamp]);
          } else {
            elements.push(0);
          }
        });
        this.data.series.push(elements);
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.menu.dropdown {
  margin-bottom: 15px
}
</style>
