<template>
  <div class="col-6">
    <card class="card" title="Reporter Information" subTitle="Update Information about the reporter">
      <div class="row">
        <div class="col-md-12">
          <fg-input type="text"
                    label="Name"
                    :disabled="disabled"
                    :error="error.name"
                    v-model="reporterInfo.name">
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <fg-input-dropdown type="text"
                             label="Gender"
                             placeholder="e.g Female"
                             :disabled="true"
                             :error="error.gender"
                             :options="genderOptions"
                             v-model="reporterInfo.gender">
          </fg-input-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <fg-input type="number"
                    label="Age"
                    :disabled="true"
                    :error="error.age"
                    v-model="reporterInfo.age">
          </fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <fg-input type="text"
                    label="Phone Number"
                    :disabled="disabled"
                    :error="error.phone"
                    v-model="reporterInfo.phone">
          </fg-input>
        </div>
      </div>
      <p-button type="success"
                style="margin-left: auto; display: block;"
                round
                :disabled="disabled"
                :loading="isUpdatingReporterInfo"
                @click.native.prevent="submitReporterInfoUpdate">
        Update Reporter Information
      </p-button>
    </card>
  </div>
</template>
<script>

import { getCounsellor } from "@/utils/auth";
import {mapActions, mapState} from "vuex";
import swal from "sweetalert";
import router from "@/router";

export default {
  data() {
    return {
      reporterInfo: {
        age: null,
        gender: "",
        name: "",
        phone: "",
        type: "Mandated Reporter"
      },
      genderOptions: [
        {key: 1, value: "Female", text: "Female"},
        {key: 0, value: "Male", text: "Male"},
      ],
    };
  },
  props: {
    updateForm: {
      type: Function,
      required: true,
    },
    formTab: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
    },
    error: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState("cases", ["isUpdatingReporterInfo"]),
  },
  watch: {
    reporterInfo: {
      deep: true,
      handler(newValue) {
        if (newValue.age && typeof newValue.age === 'string') newValue.age = Number(newValue.age);
        this.updateForm(1, newValue);
      },
    },
  },
  mounted() {
    this.reporterInfo = {...this.reporterInfo, ...this.data};
    if (
      this.$route.params &&
      this.$route.params.reporterInfo &&
      this.$route.params.reporterInfo.phone
    ) {
      this.reporterInfo.phone = this.$route.params.reporterInfo.phone;
    }
  },
  methods: {
    ...mapActions("cases", ["updateReporterInfo"]),
    async submitReporterInfoUpdate() {
      try {
        const results = await swal({
          title: "Update Reporter Information",
          text: `Are you sure you want to update reporter information?`,
          buttons: {
            cancel: true,
            confirm: true,
          },
        })
        if (results) {
          const counsellor = getCounsellor();
          const params = {
            ...this.reporterInfo,
            caseId: Number(this.$route.params.id),
            counsellorId: counsellor.counsellorId,
            workspaceId: counsellor.workspaceId,
          };
          console.log("ABOUT TO SAVE updateReporterInfo  with param " + JSON.stringify(params));
          await this.updateReporterInfo(params);
          this.$notify({
            message: 'Reporter Information Updated Successfully',
            icon: "ti-check-box",
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success'
          });
          await router.push({path: `/reports/details/${this.$route.params.id}`})
        }
        else {
          this.$notify({
            message: 'Cancelled Reporter information was not updated :)',
            icon: "ti-alert",
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning'
          });
        }
      } catch(ex) {
        console.log('error', ex);
        this.$notify({
          message: 'Error Updating Reporter Information, please try again later',
          icon: "ti-alert",
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      }
    }
  }
};
</script>
<style>
</style>
