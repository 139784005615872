<template>
  <div>
    <!--Stats cards-->
    <div class="row">
      <router-link :to="{path:'/calls/logs'}" class="col-md-6 col-xl-4" v-for="stats in conversationStatsCards" :key="stats.title">
        <stats-card>
          <div class="icon-big text-center" :class="`icon-${stats.type}`" slot="header">
            <i :class="stats.icon"></i>
          </div>
          <div class="numbers" slot="content">
            <p>{{stats.title}}</p>
            {{stats.value}}
          </div>
<!--          <div class="stats" slot="footer">-->
<!--            <i :class="stats.footerIcon"></i> {{stats.footerText}}-->
<!--          </div>-->
        </stats-card>
      </router-link>
    </div>

  </div>
</template>
<script>

import {
  mapState,
  mapActions,
} from 'vuex';
import { StatsCard, ChartCard } from "@/components/index";
import { getCounsellor } from "@/utils/auth";
export default {
  name: "conversations-card",
  components: {
    StatsCard,
    ChartCard
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      counsellor: null,
      conversationStatsCards: {
        ConversationsInQueue: {
          type: "danger",
          icon: "ti-alarm-clock",
          title: "Conversations In Queue",
          value: "0",
          footerText: "As of now",
          footerIcon: "ti-timer"
        },
        AgentCallsAccepted: {
          type: "",
          icon: "ti-pulse",
          title: "Answered Conversations",
          value: "0",
          footerText: "Today",
          footerIcon: "ti-timer"
        },
        AbandonedQueue: {
          type: "danger",
          icon: "ti-pin-alt",
          title: "Abandoned Conversations",
          value: "0",
          footerText: "Today",
          footerIcon: "ti-timer"
        },
        AgentCallsMissed: {
          type: "danger",
          icon: "ti-share-alt",
          title: "Missed Conversations",
          value: "0",
          footerText: "Today",
          footerIcon: "ti-timer"
        },
        InboundCalls: {
          type: "success",
          icon: "ti-cloud-down",
          title: "Inbound Conversations",
          value: "0",
          footerText: "Today",
          footerIcon: "ti-timer"
        },
        OutboundCalls: {
          type: "success",
          icon: "ti-cloud-up",
          title: "Outbound Conversations",
          value: "0",
          footerText: "Today",
          footerIcon: "ti-timer"
        },
        // TotalCalls: {
        //   type: "success",
        //   icon: "ti-layers-alt",
        //   title: "Total Conversations",
        //   value: "0",
        //   footerText: "Today",
        //   footerIcon: "ti-timer"
        // },
        // OnlineAgents: {
        //   type: "info",
        //   icon: "ti-headphone-alt",
        //   title: "Available Agents",
        //   value: "0",
        //   footerText: "As of now",
        //   footerIcon: "ti-timer"
        // },
        // AwayAgents: {
        //   type: "warning",
        //   icon: "ti-help-alt",
        //   title: "Away Agents",
        //   value: "0",
        //   footerText: "As of now",
        //   footerIcon: "ti-timer"
        // }
      },
      polling: null
    };
  },
  watch: {
    dashboardMetricsCount(newValue) {
      Object.keys(newValue.metrics).forEach(caseKey => {
        if (this.conversationStatsCards[caseKey]){
          this.conversationStatsCards[caseKey].value = newValue.metrics[caseKey]
        }
      })
    }
  },
  computed: {
    ...mapState('cases', [
      'dashboardMetricsCount'
    ]),
  },
  created() {
    this.counsellor = getCounsellor();
    this.pollDashboardMetricsCount();
    this.schedulePollData()
  },
  methods: {
    ...mapActions('cases', [
      'fetchDashboardMetricsCount',
    ]),
    pollDashboardMetricsCount() {
      console.log("pollDashboardMetricsCount called")
      this.fetchDashboardMetricsCount({workspaceId: this.counsellor.workspaceId})
    },
    schedulePollData () {
      this.polling = setInterval(() => {
        this.pollDashboardMetricsCount()
      }, 10000)
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
};
</script>
<style lang="scss" scoped>
h3{
  margin-top: 0;
}
.side-actions{
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  flex-direction: row-reverse;
  vertical-align: baseline;
  a{
    margin-bottom: 15px;
  }
}
</style>
