<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-6" >
          <card class="card" title="Update Case Status">
            <div class="col-md-6">
              <fg-input-dropdown type="text"
                                 label="Current Case Status"
                                 placeholder="e.g Ongoing"
                                 :error="error.status"
                                 :options="caseStatusOptions"
                                 :disabled="isStatusClosed"
                                 v-model="formData.caseStatus">
              </fg-input-dropdown>
            </div>
            <p-button type="danger"
                      style="margin-left: auto; display: block;"
                      round
                      :disabled="isStatusClosed"
                      :loading="isUpdatingCaseStatus"
                      @click.native.prevent="submitCaseStatusUpdate">
              Update Case Status
            </p-button>
          </card>
        </div>
      </div>
    </div>
    <form class="col-12" @submit.prevent>
      <div class="row">
        <update-case-form1
          :error="error.form1"
          :formTab="formTab"
          :data="formData.reporterInfo"
          :updateForm="updateForm"
          :disabled="isStatusClosed"
          v-if="formTab === '1' && isSurvivor === false"
        />
        <update-case-form2
          :error="error.form2"
          :formTab="formTab"
          :data="formData.survivorInfo"
          :updateForm="updateForm"
          :disabled="isStatusClosed"
          v-if="formTab === '1'"
        />
        <update-case-form3
          :error="error.form3"
          :formTab="formTab"
          :data="formData.perpetratorInfo"
          :updateForm="updateForm"
          :disabled="isStatusClosed"
          v-if="formTab === '2'"
        />
        <update-case-form4
          :error="error.form4"
          :formTab="formTab"
          :data="formData.generalInfo"
          :updateForm="updateForm"
          :disabled="isStatusClosed"
          v-if="formTab === '2'"
        />
      </div>
      <div class="row">
        <div class="col-6">
          <p-button
            type="info"
            v-if="formTab === '1'"
            round
            @click.native.prevent="moveBackToCaseDetails"
          >Back</p-button
          >
          <p-button
            type="info"
            v-if="formTab === '2'"
            round
            @click.native.prevent="formTab = '1'"
            >Back</p-button
          >
        </div>
        <div class="col-6">
          <p-button
            type="info"
            v-if="formTab === '1'"
            style="margin-left: auto; display: block"
            round
            @click.native.prevent="moveToNextTab"
            >Next</p-button
          >
        </div>
        <div class="col-12">
          <div class="border-top my-3"></div>
        </div>
        <div class="col-12">
          <card class="card" title="Add any additional notes here.">
            <div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Add any additional notes or action taken here.</label>
                    <textarea
                      rows="8"
                      class="form-control border-input"
                      placeholder="Add notes"
                      v-model="notes"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <p-button type="success"
                      style="margin-left: auto; display: block;"
                      round
                      :loading="isSubmittingCaseNote"
                      @click.native.prevent="saveMyNote">
              Submit Note
            </p-button>
          </card>
        </div>
      </div>
    </form>
  </div>
</template>
<script>

import { mapState, mapActions } from "vuex";
import swal from "sweetalert";
import { getCounsellor } from "@/utils/auth";
import UpdateCaseForm1 from "./UpdateForm1.vue";
import UpdateCaseForm2 from "./UpdateForm2.vue";
import UpdateCaseForm3 from "./UpdateForm3.vue";
import UpdateCaseForm4 from "./UpdateForm4.vue";
import router from "@/router";

export default {
  data() {
    return {
      counsellor: null,
      notes: '',
      formTab: "1",
      isSurvivor: "false",
      isStatusClosed: false,
      caseStatusOptions: [
        {key: 0, value: "Ongoing",text: "Ongoing"},
        {key: 1, value: "Pending",text: "Pending"},
        {key: 2, value: "Struck Out",text: "Struck Out"},
        {key: 3, value: "Resolved",text: "Resolved"},
        {key: 4, value: "Prosecuted",text: "Prosecuted"},
      ],
      formData: {
        caseStatus: null,
        reporterInfo: {},
        perpetratorInfo: {},
        generalInfo: {},
      },
      error: {
        form1: {
          age: null,
          gender: null,
          name: null,
          phone: null,
          type: null,
        },
        form2: {
          age: null,
          gender: null,
          address: null,
          employmentStatus: null,
          maritalStatus: null,
          name: null,
          phone: null,
        },
        form3: {
          address: null,
          ageRange: null,
          employmentStatus: null,
          gender: null,
          name: null,
          phone: null,
          relationship: null,
        },
        form4: {
          lgaOfOccurrence: null,
          nature: null,
          referredBy: null,
          status: null,
        },
      }
    };
  },
  watch: {
    notes: function (val) {
      this.updateForm(0, val);
    },
  },
  computed: {
    ...mapState("state", ["stateLga"]),
    ...mapState("auth", ["referralAgencies", "isFetchingReferralAgencies"]),
    ...mapState("cases", [
      "reportDetails",
      "isFetchingReportDetails",
      "isAcceptingCase",
      "isSubmittingCaseNote",
      "isClosingCase",
      "isTransferringCase",
      "isUpdatingCaseStatus"
    ]),
    ...mapState("auth", ["counsellorManagers"]),
    caseManagerOptions() {
      return this.counsellorManagers.map((v, idx) => {
        return {
          key: idx,
          value: v.id.toString(),
          text: `${v.name} - ${v.role}`,
        };
      });
    },
    lgaOfOccurrenceOptions() {
      return this.stateLga.map((v, idx) => {
        return {
          key: idx,
          value: v,
          text: v,
        };
      });
    },
    referralAgency() {},
  },
  components: {
    UpdateCaseForm1,
    UpdateCaseForm2,
    UpdateCaseForm3,
    UpdateCaseForm4,
  },
  async mounted() {
    this.counsellor = getCounsellor();
    await this.fetchStateLga({ stateIso2: this.counsellor.stateIso });

    const params = {
      id: this.$route.params.id,
      workspaceId: this.counsellor.workspaceId,
    };
    await this.fetchCounsellorManagers({
      workspaceId: this.counsellor.workspaceId,
    });
    await this.fetchReportDetails(params);
    console.log('Details', JSON.stringify(this.reportDetails), this.formData);
    this.updateData(this.reportDetails);
  },
  methods: {
    ...mapActions("cases", [
      "submitCase",
      "fetchReportDetails",
      "acceptCase",
      "closeCase",
      "transferCase",
      "submitCaseNote",
      "updateCaseStatus"
    ]),
    ...mapActions("state", ["fetchStateLga"]),
    ...mapActions("auth", ["fetchReferralAgencies"]),
    ...mapActions("auth", ["fetchCounsellorManagers"]),
    updateForm(formNumber, data) {
      switch (formNumber) {
        case 0:
          this.notes = data;
          break;
        case 1:
          this.formData.reporterInfo = data;
          break;
        case 2:
          this.formData.survivorInfo = data;
          break;
        case 3:
          this.formData.perpetratorInfo = data;
          break;
        case 4:
          this.formData.generalInfo = data;
          break;
        default:
          break;
      }
    },
    updateData(data) {
      this.isStatusClosed = data.status === "Struck Out" ||
        data.status === "Prosecuted" ||
        data.status === "Closed" ||
        data.status === "Resolved";

      this.formData.caseStatus = data.status;

      // Survivor Info
      this.formData.survivorInfo.age = data.survivorAge;
      this.formData.survivorInfo.gender = data.survivorGender;
      this.formData.survivorInfo.name = data.survivorName;
      this.formData.survivorInfo.address = data.survivorAddress;
      this.formData.survivorInfo.phone = data.survivorPhone;
      this.formData.survivorInfo.maritalStatus = data.survivorMaritalStatus;
      this.formData.survivorInfo.employmentStatus = data.survivorEmploymentStatus;
      // Reporter Info
      this.formData.reporterInfo.age = data.reporterAge;
      this.formData.reporterInfo.type = data.reporterType;
      this.formData.reporterInfo.name = data.reporterName;
      this.formData.reporterInfo.gender = data.reporterGender;
      this.formData.reporterInfo.phone = data.reporterPhone;
      // is survivor
      this.isSurvivor = data.reporterType === "Survivor";
      // perpetratorInfo Info
      this.formData.perpetratorInfo.ageRange = data.perpetratorAgeRange;
      this.formData.perpetratorInfo.name = data.perpetratorName;
      this.formData.perpetratorInfo.address = data.perpetratorAddress;
      this.formData.perpetratorInfo.phone = data.perpetratorPhone;
      this.formData.perpetratorInfo.maritalStatus = data.survivorMaritalStatus;
      this.formData.perpetratorInfo.gender = data.perpetratorGender;
      this.formData.perpetratorInfo.relationship = data.perpetratorRelationship;
      this.formData.perpetratorInfo.employmentStatus = data.perpetratorEmploymentStatus;
      // generalInfo
      this.formData.generalInfo.dateOfOccurrence = data.dateOfOccurrence;
      this.formData.generalInfo.lgaOfOccurrence = data.lgaOfOccurrence;
      this.formData.generalInfo.referredBy = data.referredBy;
      this.formData.generalInfo.nature = data.nature;
      this.caseManager = data.caseManagerId;
    },
    moveToNextTab() {
      if (this.isSurvivor === "true") {
        this.formData.reporterInfo = {
          type: "Survivor",
        };
      }
      this.formTab = "2";
    },
    moveBackToCaseDetails() {
      router.push({path: `/reports/details/${this.$route.params.id}`})
    },
    async saveMyNote() {
      try{
        if (this.notes) {
          const results = await swal({
            title: "Add additional note",
            text: `Are you sure you want to add additional note to the case?`,
            buttons: {
              cancel: true,
              confirm: true,
            },
          })
          if (results) {
            const params = {
              caseId: Number(this.$route.params.id),
              requesterId: this.counsellor.counsellorId,
              workspaceId: this.counsellor.workspaceId,
              requesterName: this.counsellor.name,
              notes: this.notes
            };
            console.log("ABOUT TO SAVE saveMyNote  with param " + JSON.stringify(params));
            await this.submitCaseNote(params);
            this.$notify({
              message: 'Note Saved',
              icon: "ti-check-box",
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'success'
            });
            await router.push({path: `/reports/details/${this.$route.params.id}`})
          }
          else {
            this.$notify({
              message: 'Cancelled This case status was not updated :)',
              icon: "ti-alert",
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning'
            });
          }
        }
        else {
          this.$notify({
            message: 'Please enter a note, before submitting',
            icon: "ti-alert",
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
        }
      } catch(ex) {
        console.log('error', ex);
        this.$notify({
          message: 'Error Adding Note, please try again later',
          icon: "ti-alert",
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      }
    },
    async submitCaseStatusUpdate() {
      try {
        const results = await swal({
          title: "Change Case Status",
          text: `Are you sure you want to change this case status to: ${this.formData.caseStatus}? You might not be able to edit this case anymore`,
          buttons: {
            cancel: true,
            confirm: true,
          }
        })
        if (results) {
          const params = {
            workspaceId: getCounsellor().workspaceId,
            counsellorId: getCounsellor().counsellorId,
            caseId: this.$route.params.id,
            status: this.formData.caseStatus
          };
          console.log("ABOUT TO SAVE updateCaseStatus  with param " + JSON.stringify(params));
          await this.updateCaseStatus(params);
          this.$notify({
            message: 'Case Status Updated Successfully',
            icon: "ti-check-box",
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success'
          });
          if (
            this.formData.caseStatus === "Struck Out" ||
            this.formData.caseStatus === "Prosecuted" ||
            this.formData.caseStatus === "Closed" ||
            this.formData.caseStatus === "Resolved"
          ) {
            await router.push({path: `/reports/details/${this.$route.params.id}`})
          }
        }
        else {
          this.$notify({
            message: 'Cancelled This case status was not updated :)',
            icon: "ti-alert",
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning'
          });
        }
      } catch(ex) {
        console.log('error', ex);
        this.$notify({
          message: 'Error Updating Case Status, please try again later',
          icon: "ti-alert",
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.contact-number {
  color: #68b3c8;
  cursor: pointer;
  &:hover {
    color: #3091b2;
  }
}
</style>
