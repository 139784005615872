<template>
  <ul class="nav">
    <!-- <tr v-if="isFetchingCounsellorList">
    <td></td>
    </tr>
    <tr v-else-if="filteredCounsellorList.length === 0">
    <td>No Members Found</td>
    </tr>-->
    <li class="nav-item header">Team</li>
    <li class="nav-item" v-for="(item, idx) in filteredCounsellorList" :key="idx">
      <slot :row="item">
        <span
          :class="[
            'status', {
            'green': item.status === 'Available',
            'orange': item.status === 'Away'}]"
        />
        {{ item.name }}
        {{ item.name === counsellor.name ? ' (you)' : '' }}
      </slot>
    </li>
  </ul>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { EventBus } from "@/utils";
import { getCounsellor } from "@/utils/auth";
export default {
  data() {
    return {
      counsellor: {},
      filteredCounsellorList: [],
      filter: {
        workspaceId: null,
        page: 0,
        count: 500,
      },
    };
  },
  created() {
    this.counsellor = getCounsellor();
    this.filter.workspaceId = this.counsellor.workspaceId;
    this.fetchWithFilter();
    EventBus.$on("handleWsMessage", (data) => this.handleWsMessage(data));
  },
  watch: {
    search(newValue) {
      if (newValue && newValue !== "") {
        this.filteredCounsellorList = this.counsellorList.filter((member) => {
          return (
            member.name.toLowerCase().includes(newValue) ||
            member.email.toLowerCase().includes(newValue) ||
            member.phone.toString().toLowerCase().includes(newValue) ||
            member.role.toLowerCase().includes(newValue)
          );
        });
      } else {
        this.filteredCounsellorList = this.counsellorList;
      }
    },
    counsellorList: {
      deep: true,
      handler(newValue) {
        this.filteredCounsellorList = null;
        console.log({newValue})
        this.filteredCounsellorList = [...newValue]
        this.filteredCounsellorList.sort((a, b) => {
          let result = -1;
          if (a.status === 'Available') {
            result = 1;
          } else {
            if (a.status === 'Away') {
              result = 1;
            } else {
              if (a.status === 'Offline') {
                result = 1;
              }
            }
          }
          return result;
        })
          // this.filteredCounsellorList.sort((a, b) => (a.status > b.status) ? 1 : (a.status === b.status) ? ((a.lastLogin > b.lastLogin) ? -1 : 1) : -1 )
          //.sort((a, b) => (b.lastLogin - a.lastLogin))
          .sort((a, b) => (a.name === this.counsellor.name) ? -1 : 1)
      },
    },
  },
  computed: {
    ...mapState("auth", ["counsellorList", "isFetchingCounsellorList"]),
  },
  methods: {
    ...mapActions("auth", ["fetchCounsellorList", "updateCounsellorList"]),
    fetchWithFilter() {
      const params = this.filter;
      Object.keys(params).forEach(
        (key) =>
          (params[key] === null || params[key] === "") && delete params[key]
      );
      this.fetchCounsellorList(params);
    },
    async handleWsMessage(json) {
      switch (json.type) {
        case "WorkspaceAgentStatusUpdate":
          let counsellorList = [...this.counsellorList]
          counsellorList = counsellorList.map(counsellor => {
            let mutableCounsellor = {...counsellor};
            if (mutableCounsellor.uuid === json.agentId) {
              mutableCounsellor.status = json.status;
            }
            return mutableCounsellor;
          });
          if (this.counsellor.counsellorUuid === json.agentId) {
            await this.updateCounsellorList(counsellorList);
            break;
          } else {
            this.fetchWithFilter()
            await this.updateCounsellorList(counsellorList);
            break ;
          }
        default:
          break;
      }
    },

  },
};
</script>
<style lang="scss" scoped>
ul {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin: 20px 25px;
  padding-top: 15px;
  max-height: 307px;
  overflow: auto;
  height: initial;
  position: relative;
  li {
    display: flex;
    cursor: pointer;
    margin: 6px 0;
    color: #C0C0C0;
    font-size: 14px;
    left: 25px;
    &.header {
      margin-top: 25px;
      margin-bottom: 10px;
      font-size: 15px;
      font-weight: 200;
      color: #41b883;
    }
    span.status{
        width: 8px;
        height: 8px;
        border-radius: 200px;
        display: inline-block;
        margin-right: 15px;
        background: #CCC;
        align-self: center;
        &.green{
            background: #41b883;
        }
        &.red{
            background: #EB5E28;
        }
        &.orange{
            background: #F3BB45;
        }
    }
  }
}
</style>
