<template>
  <div>
    <div class="row">
      <div class="col-md-9">
          <h3>Hello, {{counsellor.name}}</h3>
          <p class="text-muted">Operating State: <b>{{location}}</b></p>
          <br/>
          <p class="text-muted"><b>CASE OVERVIEW</b></p>
      </div>
      <div class="col-3 side-actions" v-if="counsellor.role === 'Administrator' || counsellor.role === 'Call Handler'" >
        <router-link class="btn btn-round btn-info" :to="{path:'/create'}">Add New Case</router-link>
      </div>
    </div>
    <div class="row">
      <router-link :to="{path:'/reports'}" class="col-md-6 col-xl-4" v-for="stats in statsCards" :key="stats.title">
        <stats-card>
          <div class="icon-big text-center" :class="`icon-${stats.type}`" slot="header">
            <i :class="stats.icon"></i>
          </div>
          <div class="numbers" slot="content">
            <p>{{stats.title}}</p>
            {{stats.value}}
          </div>
          <!-- <div class="stats" slot="footer">
            <i :class="stats.footerIcon"></i> {{stats.footerText}}
          </div> -->
        </stats-card>
      </router-link>
    </div>
    <br/>
    <br/>
    <div class="row">
      <div class="col-md-9">
        <p class="text-muted"><b>CONVERSATIONS</b></p>
      </div>
    </div>
    <conversations-card></conversations-card>
  </div>
</template>
<script>
import {
    mapState,
    mapActions,
} from 'vuex';
import { StatsCard, ChartCard } from "@/components/index";
import { getCounsellor } from "@/utils/auth";
import ConversationsCard from "@/pages/Conversations";
export default {
  components: {
    ConversationsCard,
    StatsCard,
    ChartCard
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      counsellor: null,
      states: [
        { code: "NG-LA", name: "Lagos" },
        { code: "NG-FC", name: "Abuja" },
        { code: "NG-AD", name: "Adamawa" }
      ],
      statsCards: {
        totalCase: {
          type: "success",
          icon: "ti-layers-alt",
          title: "Total Cases",
          value: "0",
          footerText: "Overall",
          footerIcon: "ti-reload"
        },
        Ongoing: {
          type: "",
          icon: "ti-pulse",
          title: "Ongoing Cases",
          value: "0",
          footerText: "Overall",
          footerIcon: "ti-calendar"
        },
        Pending: {
          type: "warning",
          icon: "ti-pin-alt",
          title: "Pending Cases",
          value: "0",
          footerText: "Overall",
          footerIcon: "ti-timer"
        },
        "Struck Out": {
          type: "danger",
          icon: "ti-trash",
          title: "Struck Out Cases",
          value: "0",
          footerText: "Overall",
          footerIcon: "ti-reload"
        },
        Resolved: {
          type: "info",
          icon: "ti-stamp",
          title: "Resolved Cases",
          value: "0",
          footerText: "Overall",
          footerIcon: "ti-reload"
        },
        Prosecuted: {
          type: "success",
          icon: "ti-help-alt",
          title: "Prosecuted Cases",
          value: "0",
          footerText: "Overall",
          footerIcon: "ti-reload"
        }
      },
    };
  },
  watch: {
    casesCount(newValue) {
      console.log({newValue})
      Object.keys(newValue).forEach(caseKey => {
        if (this.statsCards[caseKey]){
          this.statsCards[caseKey].value = newValue[caseKey]
        }
      })
      Object.keys(newValue.statusCount).forEach(caseKey => {
        if (this.statsCards[caseKey]){
          this.statsCards[caseKey].value = newValue.statusCount[caseKey]
        }
      })

    }
  },
  computed: {
     ...mapState('cases', [
        'casesCount'
    ]),
    location() {
      let result = 'N/A';
      const stateIso2 = this.states.find(s => s.code === this.counsellor.stateIso);
      if (stateIso2) result = stateIso2.name;
      return result;
    }
  },
  created() {
    this.counsellor = getCounsellor();
    this.fetchCasesCount({workspaceId: this.counsellor.workspaceId})
    console.log(this.counsellor)
  },
  methods: {
    ...mapActions('cases', [
            'fetchCasesCount',
        ]),
  }
};
</script>
<style lang="scss" scoped>
h3{
  margin-top: 0;
}
.side-actions{
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    flex-direction: row-reverse;
    vertical-align: baseline;
    a{
        margin-bottom: 15px;
    }
}
</style>
