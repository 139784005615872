<template>
  <div class="col-6">
    <card class="card"  title="Survivor Information" subTitle="Update Information about the survivor">
      <div class="row">
        <div class="col-md-12">
          <fg-input type="text"
                    :error="error.name"
                    :disabled="disabled"
                    label="Survivor's name"
                    v-model="survivorInfo.name">
          </fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <fg-input type="text"
                    :error="error.address"
                    :disabled="disabled"
                    label="Address"
                    v-model="survivorInfo.address">
          </fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <fg-input-dropdown type="text"
                             label="Marital Status"
                             placeholder="e.g Single"
                             :disabled="true"
                             :error="error.maritalStatus"
                             :options="maritalOptions"
                             v-model="survivorInfo.maritalStatus">
          </fg-input-dropdown>
        </div>
        <div class="col-md-6">
          <fg-input-dropdown type="text"
                             label="Employment Status"
                             placeholder="e.g Employed"
                             :disabled="true"
                             :error="error.employmentStatus"
                             :options="employStatusOptions"
                             v-model="survivorInfo.employmentStatus">
          </fg-input-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <fg-input type="text"
                    label="Phone Number"
                    :error="error.phone"
                    :disabled="disabled"
                    v-model="survivorInfo.phone">
          </fg-input>
        </div>
        <div class="col-md-4">
          <fg-input-dropdown type="text"
                             label="Gender"
                             :disabled="disabled"
                             :error="error.gender"
                             :options="genderOptions"
                             v-model="survivorInfo.gender">
          </fg-input-dropdown>
        </div>
        <div class="col-md-4">
          <fg-input type="number"
                    label="Age"
                    :disabled="true"
                    :error="error.age"
                    v-model="survivorInfo.age">
          </fg-input>
        </div>
      </div>
      <p-button type="success"
                style="margin-left: auto; display: block;"
                round
                :disabled="disabled"
                :loading="isUpdatingSurvivorInfo"
                @click.native.prevent="submitSurvivorInfoUpdate">
        Update Survivor Information
      </p-button>
    </card>
  </div>
</template>
<script>

import { getCounsellor } from "@/utils/auth";
import {mapActions, mapState} from "vuex";
import swal from "sweetalert";
import router from "@/router";

export default {
  data() {
    return {
      survivorInfo: {
        age: null,
        gender: null,
        address: "",
        employmentStatus: "",
        maritalStatus: "",
        name: "",
        phone: ""
      },
      genderOptions: [
        {key: 1, value: "Female", text: "Female"},
        {key: 0, value: "Male", text: "Male"},
      ],
      maritalOptions: [
        {key: 0, value: "Single", text: "Single"},
        {key: 1, value: "Married", text: "Married"},
        {key: 2, value: "Divorced", text: "Divorced"},
        {key: 3, value: "Other", text: "Other"},
      ],
      employStatusOptions: [
        {key: 0, value: "Employed",text: "Employed"},
        {key: 1, value: "Unemployed",text: "Unemployed"},
        {key: 2, value: "SelfEmployed",text: "Self Employed"},
        {key: 3, value: "Student",text: "Student"}
      ],
    };
  },
  props: {
    updateForm: {
      type: Function,
      required: true,
    },
    formTab: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
    },
    error: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState("cases", ["isUpdatingSurvivorInfo"]),
  },
  watch: {
    survivorInfo: {
      deep: true,
      handler(newValue) {
        //console.log("survivorInfo watcher ", JSON.stringify(newValue));

        if (newValue.age && typeof newValue.age === 'string') newValue.age = Number(newValue.age);
        this.updateForm(2, newValue);
      },
    },
  },
  mounted() {
    this.survivorInfo = {...this.survivorInfo, ...this.data};
  },
  methods: {
    ...mapActions("cases", ["updateSurvivorInfo"]),
    async submitSurvivorInfoUpdate() {
      try {
        const results = await swal({
          title: "Update Survivor Information",
          text: `Are you sure you want to update survivor information?`,
          buttons: {
            cancel: true,
            confirm: true,
          },
        })
        if (results) {
          const counsellor = getCounsellor();
          const params = {
            ...this.survivorInfo,
            caseId: Number(this.$route.params.id),
            counsellorId: counsellor.counsellorId,
            workspaceId: counsellor.workspaceId
          };
          console.log("ABOUT TO SAVE updateSurvivorInfo  with param " + JSON.stringify(params));
          await this.updateSurvivorInfo(params);
          this.$notify({
            message: 'Survivor Information Updated Successfully',
            icon: "ti-check-box",
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success'
          });
          await router.push({path: `/reports/details/${this.$route.params.id}`})
        }
        else {
          this.$notify({
            message: 'Cancelled Survivor information was not updated :)',
            icon: "ti-alert",
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning'
          });
        }
      } catch(ex) {
        console.log('error', ex);
        this.$notify({
          message: 'Error Updating Survivor Information, please try again later',
          icon: "ti-alert",
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      }
    }
  }
};
</script>
<style>
</style>
