import {
  fetchReportsData,
  downloadReportsData,
  fetchReportDetailsData,
  fetchMyCasesData,
  fetchCasesCountData,
  submitCaseData,
  acceptCase,
  transferCase,
  closeCase,
  submitCaseNote,
  findReportByPhoneNumber,
  fetchDashboardMetricsCount,
  updateSurvivorInfo,
  updateReporterInfo,
  updatePerpetratorInfo,
  updateCaseOverviewInfo,
  updateCaseStatus,
} from '@/utils/api/cases';

const defaultState = {
    reports: [],
    isFetchingReports: false,

    callReports: [],
    isFetchingCallReports: false,

    reportCsv: null,
    isDownloadingReports: false,

    reportDetails: {},
    isFetchingReportDetails: false,

    myCases: {},
    isFetchingMyCases: false,
    isDownloadingMyCases: false,

    casesCount: {},
    isFetchingCasesCount: false,

    dashboardMetricsCount: {},
    isFetchingDashboardMetricsCount: false,

    isSubmittingCase: false,

    isAcceptingCase: false,

    isClosingCase: false,

    isTransferringCase: false,

    isSubmittingCaseNote: false,

    isUpdatingSurvivorInfo: false,

    isUpdatingReporterInfo: false,

    isUpdatingPerpetratorInfo: false,

    isUpdatingCaseInfo: false,

    isUpdatingCaseStatus: false,
};

const mutations = {
    setIsFetchingReports(state, status) {
        state.isFetchingReports = status;
    },
    setReports(state, reports) {
        state.reports = reports;
    },

    setIsFetchingCallReports(state, status) {
        state.isFetchingCallReports = status;
    },
    setCallReports(state, callReports) {
        state.callReports = callReports;
    },

    setReportCsv(state, reports) {
        state.reportCsv = reports;
    },
    setIsDownloadingReports(state, status) {
        state.isDownloadingReports = status;
    },

    setIsFetchingReportDetails(state, status) {
        state.isFetchingReportDetails = status;
    },
    setReportDetails(state, data) {
        state.reportDetails = data;
    },

    setIsFetchingMyCases(state, status) {
        state.isFetchingMyCases = status;
    },
    setIsDownloadingMyCases(state, status) {
        state.isDownloadingMyCases = status;
    },
    setMyCases(state, myCases) {
        state.myCases = myCases;
    },

    setIsFetchingCasesCount(state, status) {
        state.isFetchingCasesCount = status;
    },
    setCasesCount(state, casesCount) {
        state.casesCount = casesCount;
    },

    setIsDashboardMetricsCount(state, status) {
      state.isFetchingDashboardMetricsCount = status;
    },
    setDashboardMetricsCount(state, dashboardMetricsCount) {
      state.dashboardMetricsCount = dashboardMetricsCount;
    },

    setIsSubmittingCase(state, status) {
        state.isSubmittingCase = status;
    },

    setIsSubmittingCaseNote(state, status) {
        state.isSubmittingCaseNote = status;
    },

    setIsTransferringCase(state, status) {
        state.isTransferringCase = status;
    },

    setIsClosingCase(state, status) {
        state.isClosingCase = status;
    },

    setIsAcceptingCase(state, status) {
        state.isAcceptingCase = status;
    },

    setIsUpdatingSurvivorInfo(state, status) {
        state.isUpdatingSurvivorInfo = status;
    },

    setIsUpdatingReporterInfo(state, status) {
        state.isUpdatingReporterInfo = status;
    },

    setIsUpdatingPerpetratorInfo(state, status) {
        state.isUpdatingPerpetratorInfo = status;
    },

    setIsUpdatingCaseInfo(state, status) {
        state.isUpdatingCaseInfo = status;
    },

    setIsUpdatingCaseStatus(state, status) {
        state.isUpdatingCaseStatus = status;
    }

};

const actions = {
    async fetchReports({ commit }, data) {
        try {
            commit('setIsFetchingReports', true);
            const results = await fetchReportsData(data);
            commit('setReports', results);
            commit('setIsFetchingReports', false);
        } catch (ex) {
            commit('setIsFetchingReports', false);
            throw ex;
        }
    },

    async fetchCallReports({ commit }, data) {
        try {
            commit('setIsFetchingCallReports', true);
            const results = await findReportByPhoneNumber(data);
            console.log({results})
            commit('setCallReports', results.entries ? results.entries : []);
            commit('setIsFetchingCallReports', false);
        } catch (ex) {
            commit('setIsFetchingCallReports', false);
            throw ex;
        }
    },

    async downloadReports({ commit }, data) {
        try {
            commit('setIsDownloadingReports', true);
            const results = await downloadReportsData(data);
            commit('setReportCsv', results);
            commit('setIsDownloadingReports', false);
        } catch (ex) {
            commit('setIsDownloadingReports', false);
            throw ex;
        }
    },

    async fetchReportDetails({ commit }, data) {
        try {
            commit('setIsFetchingReportDetails', true);
            const results = await fetchReportDetailsData(data);
            commit('setReportDetails', results);
            commit('setIsFetchingReportDetails', false);
        } catch (ex) {
            commit('setIsFetchingReportDetails', false);
            throw ex;
        }
    },

    async fetchMyCases({ commit }, data) {
        try {
            commit('setIsFetchingMyCases', true);
            const results = await fetchMyCasesData(data);
            commit('setMyCases', results);
            commit('setIsFetchingMyCases', false);
        } catch (ex) {
            commit('setIsFetchingMyCases', false);
            throw ex;
        }
    },

    async fetchCasesCount({ commit }, data) {
        try {
            commit('setIsFetchingCasesCount', true);
            const results = await fetchCasesCountData(data);
            commit('setCasesCount', results);
            commit('setIsFetchingCasesCount', false);
        } catch (ex) {
            commit('setIsFetchingCasesCount', false);
            throw ex;
        }
    },

    async submitCase({ commit }, data) {
        try {
            commit('setIsSubmittingCase', true);
            await submitCaseData(data);
            commit('setIsSubmittingCase', false);
        } catch (ex) {
            commit('setIsSubmittingCase', false);
            throw ex;
        }
    },

    async closeCase({ commit }, data) {
        try {
            commit('setIsClosingCase', true);
            await closeCase(data);
            commit('setIsClosingCase', false);
        } catch (ex) {
            commit('setIsClosingCase', false);
            throw ex;
        }
    },

    async transferCase({ commit }, data) {
        try {
            commit('setIsTransferringCase', true);
            await transferCase(data);
            commit('setIsTransferringCase', false);
        } catch (ex) {
            commit('setIsTransferringCase', false);
            throw ex;
        }
    },

    async submitCaseNote({ commit }, data) {
        try {
            commit('setIsSubmittingCaseNote', true);
            await submitCaseNote(data);
            commit('setIsSubmittingCaseNote', false);
        } catch (ex) {
            commit('setIsSubmittingCaseNote', false);
            throw ex;
        }
    },

    async acceptCase({ commit }, data) {
        try {
            commit('setIsAcceptingCase', true);
            await acceptCase(data);
            commit('setIsAcceptingCase', false);
        } catch (ex) {
            commit('setIsAcceptingCase', false);
            throw ex;
        }
    },

  async fetchDashboardMetricsCount({ commit }, data) {
    try {
      commit('setIsDashboardMetricsCount', true);
      const results = await fetchDashboardMetricsCount(data);
      commit('setDashboardMetricsCount', results);
      commit('setIsDashboardMetricsCount', false);
    } catch (ex) {
      commit('setIsDashboardMetricsCount', false);
      throw ex;
    }
  },

  async updateSurvivorInfo({ commit }, data) {
    try {
      commit('setIsUpdatingSurvivorInfo', true);
      await updateSurvivorInfo(data);
      commit('setIsUpdatingSurvivorInfo', false);
    } catch (ex) {
      commit('setIsUpdatingSurvivorInfo', false);
      throw ex;
    }
  },

  async updateReporterInfo({ commit }, data) {
    try {
      commit('setIsUpdatingReporterInfo', true);
      await updateReporterInfo(data);
      commit('setIsUpdatingReporterInfo', false);
    } catch (ex) {
      commit('setIsUpdatingReporterInfo', false);
      throw ex;
    }
  },

  async updatePerpetratorInfo({ commit }, data) {
    try {
      commit('setIsUpdatingPerpetratorInfo', true);
      await updatePerpetratorInfo(data);
      commit('setIsUpdatingPerpetratorInfo', false);
    } catch (ex) {
      commit('setIsUpdatingPerpetratorInfo', false);
      throw ex;
    }
  },

  async updateCaseOverviewInfo({ commit }, data) {
    try {
      commit('setIsUpdatingCaseInfo', true);
      await updateCaseOverviewInfo(data);
      commit('setIsUpdatingCaseInfo', false);
    } catch (ex) {
      commit('setIsUpdatingCaseInfo', false);
      throw ex;
    }
  },

  async updateCaseStatus({ commit }, data) {
    try {
      commit('setIsUpdatingCaseStatus', true);
      await updateCaseStatus(data);
      commit('setIsUpdatingCaseStatus', false);
    } catch (ex) {
      commit('setIsUpdatingCaseStatus', false);
      throw ex;
    }
  }

};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
};
