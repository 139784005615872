<template>
  <div class="col-12">
    <div class="row">
      <cases-graph />
      <calls-graph />
    </div>
    <div class="row">
<!--      <cases-bar-graph />-->
<!--      <cases-line-graph />-->
      <reported-case-bar-graph />
      <reported-case-line-graph />
      <survivor-age-graph />
      <survivor-age-line-graph />
    </div>
    <div class="row">
      <calls-bar-graph />
      <calls-line-graph />
    </div>
    <div class="row">
      <call-count-graph />
      <call-duration-graph />
      <call-duration-bar-graph />
    </div>
  </div>
</template>
<script>

import CasesGraph from "@/pages/Analytics/PieChart/Cases.vue";
import CasesBarGraph from "@/pages/Analytics/BarChart/Cases.vue";
import ReportedCaseBarGraph from "@/pages/Analytics/BarChart/ReportedCase.vue";
import ReportedCaseLineGraph from "@/pages/Analytics/LineChart/ReportedCase.vue";
import CasesLineGraph from "@/pages/Analytics/LineChart/Cases.vue";
import SurvivorAgeGraph from "@/pages/Analytics/BarChart/SurvivorAgeGroup.vue";
import SurvivorAgeLineGraph from "@/pages/Analytics/LineChart/SurvivorAgeGroup.vue";
import CallsBarGraph from "@/pages/Analytics/BarChart/Calls.vue";
import CallsLineGraph from "@/pages/Analytics/LineChart/Calls.vue";
import CallsGraph from "@/pages/Analytics/PieChart/Calls.vue";
import CallCountGraph from "@/pages/Analytics/LineChart/CallCount.vue";
import CallDurationGraph from "@/pages/Analytics/LineChart/CallDuration.vue";
import CallDurationBarGraph from "@/pages/Analytics/BarChart/CallDuration.vue";
export default {
  components: {
    CasesGraph,
    CallsGraph,
    CasesBarGraph,
    ReportedCaseBarGraph,
    ReportedCaseLineGraph,
    CasesLineGraph,
    SurvivorAgeGraph,
    SurvivorAgeLineGraph,
    CallsBarGraph,
    CallsLineGraph,
    CallCountGraph,
    CallDurationGraph,
    CallDurationBarGraph
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  async mounted() {},
  async created() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
  .menu.dropdown {
    margin-bottom: 15px
  }
</style>
