<template>
  <div class="col-md-6 col-12">
    <chart-card title="Reported Cases Overview Pie Chart"
                sub-title="Group Case by different categories"
                v-if="!isFetchingPieCases"
                :chart-data="data"
                :chart-options="options"
                chart-type="Pie"
                footer-text='One Day Ago (Default)'
    >
      <div class="row" slot="filter">
        <div class="col-12">
          <div class="dropdown menu">
              <button class="btn btn-round btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Filter
              </button>
              <div class="dropdown-menu dropdown-menu-left p4">
                  <form class="col-12" @submit.prevent>
                    <fg-input-dropdown type="text"
                        label="Group By"
                        placeholder="e.g Nature"
                        required
                        :options="tagOptions"
                        v-model="tag">
                    </fg-input-dropdown>
                    <date-range-picker
                      v-model="dateRange"
                      ref="picker"
                      :minDate="minDate"
                      :maxDate="maxDate"
                      opens="right"
                      :locale-data="locale"
                      @update="updateValues"
                    >
                      <template v-slot:input="picker" style="min-width: 50px;">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
                    </date-range-picker>
                    <br>
                      <p-button type="success"
                              style="margin-left: auto; display: block;"
                              round
                              @click.native.prevent="fetchWithFilter">
                      Submit
                      </p-button>
                  <br />
                  </form>
              </div>
          </div>
        </div>
      </div>
      <div v-if="!data || data.series.length === 0" slot="empty">No Data Available</div>
    </chart-card>
  </div>
</template>
<script>
import {
    mapState,
    mapActions,
} from 'vuex';
import Chartist from 'chartist';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
require('chartist-plugin-legend');
import { ChartCard } from "@/components/index";
import { getCounsellor } from '@/utils/auth'
import {
  endOfTodayEndDate,
  isMoreThanAMonth,
  isOneDay,
  minDate,
  nowDate,
  oneDayAgoStartDate
} from "@/utils/utils";
import moment from "moment/moment";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
  components: {
    ChartCard,
    DateRangePicker
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      counsellor: null,
      minDate: minDate(),
      maxDate: nowDate(),
      tag: 'nature',
      dateRange: {
        startDate: oneDayAgoStartDate(),
        endDate: endOfTodayEndDate()
      },
      locale: {
        direction: 'ltr',
        format: 'mm-dd-yyyy',
        separator: ' to ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1
      },
      tagOptions: [
          {key: 0, value: "nature", text: "Case Nature"},
          {key: 1, value: "LGA", text: "Local Government Area"},
          {key: 2, value: "callHandlerUuid", text: "Call Handler"},
          {key: 3, value: "perpetratorEmploymentStatus", text: "Perpetrator Employment Status"},
          {key: 4, value: "perpetratorGender", text: "Perpetrator Gender"},
          {key: 5, value: "perpetratorRelationship", text: "Perpetrator Relationship"},
          {key: 6, value: "perpetratorAgeRange", text: "Perpetrator Age Range"},
          {key: 7, value: "survivorAge", text: "Survivor Age"},
          {key: 8, value: "survivorGender", text: "Survivor Gender"},
          {key: 9, value: "survivorMaritalStatus", text: "Survivor Marital Status"},
          {key: 10, value: "survivorEmploymentStatus", text: "Survivor Employment Status"},
          {key: 11, value: "reporterAge", text: "Reporter Age"},
          {key: 12, value: "reporterType", text: "Reporter Type"},
          {key: 13, value: "reporterGender", text: "Reporter Gender"},
          {key: 14, value: "referredBy", text: "Referred By"},
      ],
      config: {
        format: '',
        useCurrent: false,
        showClear: true,
        showClose: true,
      },
      filter: {
        metrics: [
          {
            name: "TotalReportedCases",
            tags: {
              workspaceId: []
            },
            group_by: [
              {
                name: "tag",
                tags: [
                  "nature"
                ]
              }
            ],
            aggregators: [
              {
                name: "sum",
                sampling: {
                  value: "1",
                  unit: "hours"
                },
                align_end_time: true
              }
            ]
          }
        ],
        plugins: [],
        cache_time: 0,
        start_absolute: oneDayAgoStartDate(),
        end_absolute: endOfTodayEndDate()
      },
      data: null,
      options: null,
    };
  },
  filters: {
    date(val) {
      return val ? moment(val).format('LL') : "";
    }
  },
  computed: {
    ...mapState('analytics', ['pieCases', 'isFetchingPieCases']),
    ...mapState("auth", ["counsellorList", "isFetchingCounsellorList"]),
  },
  watch: {
    async pieCases(newValue) {
      await this.formatGraphData(newValue);
    },
    tag(newValue) {
      this.filter.metrics.map(metric => {
          metric.group_by[0].tags = [newValue]
          return metric
        })
    }
  },
  async mounted() {

  },
  async created() {
    this.counsellor = getCounsellor();
    await this.fetchWithFilter()
  },
  methods: {
    ...mapActions('analytics', [
      'fetchPieCases',
    ]),
    updateValues({ startDate, endDate }) {
      this.filter.start_absolute = startDate;
      this.filter.end_absolute = endDate;
      this.fetchWithFilter();
    },
    async fetchWithFilter() {
      if (isOneDay(this.filter.start_absolute, this.filter.end_absolute)) {
        const stateDate = moment(this.filter.start_absolute)
        const endDate = moment(this.filter.end_absolute)
        this.filter.start_absolute = stateDate.startOf('day').valueOf()
        this.filter.end_absolute = endDate.endOf('day').valueOf()
        this.filter.metrics.map(metric => {
          metric.aggregators[0].sampling = {
            value: '1',
            unit: 'hours',
          };
          metric.tags.workspaceId = [this.counsellor.workspaceId]
          return metric
        })
      }
      else if (isMoreThanAMonth(this.filter.start_absolute, this.filter.end_absolute)) {
        const stateDate = moment(this.filter.start_absolute)
        const endDate = moment(this.filter.end_absolute)
        this.filter.start_absolute = stateDate.startOf('month').valueOf()
        this.filter.end_absolute = endDate.endOf('month').valueOf()
        this.filter.metrics.map(metric => {
          metric.aggregators[0].sampling = {
            value: '1',
            unit: 'months',
          };
          metric.tags.workspaceId = [this.counsellor.workspaceId]
          return metric
        })
      }
      else {
        const stateDate = moment(this.filter.start_absolute)
        const endDate = moment(this.filter.end_absolute)
        this.filter.start_absolute = stateDate.startOf('day').valueOf()
        this.filter.end_absolute = endDate.endOf('day').valueOf()
        this.filter.metrics.map(metric => {
          metric.aggregators[0].sampling = {
            value: '1',
            unit: 'days',
          };
          metric.tags.workspaceId = [this.counsellor.workspaceId]
          return metric
        })
      }

      const params = this.filter;
      Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);
      await this.fetchPieCases(params);
      // console.log({casssses: this.pieCases})
    },
    async formatGraphData() {
      this.data = {
        labels: [],
        series: []
      }
      this.options = {
          labelOffset: 70,
          labelDirection: 'explode',
          plugins: [
            Chartist.plugins.legend({
              position: 'bottom',
            }),
            Chartist.plugins.tooltip(),
            ChartistTooltip(),
          ]
      }
      this.pieCases.queries.forEach(query => {
        query.results.forEach((result, idx) => {
          switch (result.name) {
            case 'TotalReportedCases':
              if (this.tag === 'callHandlerUuid') {
                const agent = this.counsellorList.find(c => c.uuid === result.group_by[0].group[this.tag])
                agent ? this.data.labels[idx] = agent.name : this.data.labels[idx] = result.group_by[0].group[this.tag]
              } else {
                if (result.group_by) this.data.labels[idx] = result.group_by[0].group[this.tag]
              }
              result.values.forEach((value, idxx) => {
                idxx === 0 ? this.data.series[idx] = value[1] : this.data.series[idx] += value[1]
              })
              break;
            default:
              break;
          }
        })
        // console.log({datadatadatadata: this.data })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .menu.dropdown {
    margin-bottom: 15px
  }
</style>
