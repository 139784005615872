<template>
  <div class="col-md-6 col-12" style="z-index: 0">
    <chart-card title="Call Overview Pie Chart"
                sub-title="Group Call by different categories"
                v-if="!isFetchingPieCalls"
                :chart-data="data"
                :chart-options="options"
                chart-type="Pie"
                footer-text='One Day Ago (Default)'
    >
      <div class="row" slot="filter" >
        <div class="col-12">
          <div class="dropdown menu">
              <button class="btn btn-round btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Filter
              </button>
              <div class="dropdown-menu dropdown-menu-left p4">
                  <form class="col-12" @submit.prevent>
                    <fg-input-dropdown type="text"
                        label="Group By"
                        placeholder="e.g Nature"
                        required
                        :options="tagOptions"
                        v-model="tag">
                    </fg-input-dropdown>
                    <date-range-picker
                      v-model="dateRange"
                      ref="picker"
                      :minDate="minDate"
                      :maxDate="maxDate"
                      opens="right"
                      :locale-data="locale"
                      @update="updateValues"
                    >
                      <template v-slot:input="picker" style="min-width: 50px;">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
                    </date-range-picker>
                      <p-button type="success"
                              style="margin-left: auto; display: block;"
                              round
                              @click.native.prevent="fetchWithFilter">
                      Submit
                  </p-button>
                  <br />
                  </form>
              </div>
          </div>
        </div>
      </div>
      <div v-if="!data || data.series.length === 0" slot="empty">No Data Available</div>
    </chart-card>
  </div>
</template>
<script>
import {
    mapState,
    mapActions,
} from 'vuex';
import Chartist from 'chartist';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
require('chartist-plugin-legend');
import { StatsCard, ChartCard } from "@/components/index";
import { getCounsellor } from '@/utils/auth'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  isMoreThanAMonth,
  isOneDay,
  minDate,
  nowDate,
  oneDayAgoStartDate,
  endOfTodayEndDate
} from "@/utils/utils";
import moment from "moment";
export default {
  components: {
    StatsCard,
    ChartCard,
    DateRangePicker
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      counsellor: null,
      minDate: minDate(),
      maxDate: nowDate(),
      appendToBody: true,
      tag: 'agentId',
      dateRange: {
        startDate: oneDayAgoStartDate(),
        endDate: endOfTodayEndDate()
      },
      locale: {
        direction: 'ltr',
        format: 'mm-dd-yyyy',
        separator: ' to ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1
      },
      tagOptions: [
        {key: 1, value: "hangupCause", text: "Hangup Cause"},
        {key: 0, value: "callDirection", text: "Call Direction"},
        {key: 1, value: "agentId", text: "Call Handler"},
        {key: 1, value: "callSessionStatus", text: "Status"},
      ],
      config: {
        format: '',
        useCurrent: false,
        showClear: true,
        showClose: true,
      },
      filter: {
        metrics: [
          {
            name: "TotalCalls",
            tags: {
              workspaceId: []
            },
            group_by: [
              {
                name: "tag",
                tags: [
                  "agentId"
                ]
              }
            ],
            aggregators: [
              {
                name: "sum",
                sampling: {
                  value: "1",
                  unit: "hours"
                },
                align_end_time: true
              }
            ]
          }
        ],
        plugins: [],
        cache_time: 0,
        start_absolute: oneDayAgoStartDate(),
        end_absolute: endOfTodayEndDate()
      },
      data: null,
      options: null,
    };
  },
  filters: {
    date(val) {
      return val ? moment(val).format('LL') : "";
    }
  },
  computed: {
    ...mapState('analytics', ['pieCalls', 'isFetchingPieCalls']),
    ...mapState("auth", ["counsellorList", "isFetchingCounsellorList"]),
  },
  watch: {
    async pieCalls(newValue) {
      await this.formatGraphData(newValue);
    },
    tag(newValue) {
      this.filter.metrics.map(metric => {
          metric.group_by[0].tags = [newValue]
          return metric
        })
    }
  },
  async mounted() {

  },
  async created() {
    this.counsellor = getCounsellor();
    await this.fetchWithFilter()
  },
  methods: {
    ...mapActions('analytics', [
      'fetchPieCalls',
    ]),
    updateValues({ startDate, endDate }) {
      this.filter.start_absolute = startDate;
      this.filter.end_absolute = endDate;
      this.fetchWithFilter();
    },
    async fetchWithFilter() {
      if (isOneDay(this.filter.start_absolute, this.filter.end_absolute)) {
        const stateDate = moment(this.filter.start_absolute)
        const endDate = moment(this.filter.end_absolute)
        this.filter.start_absolute = stateDate.startOf('day').valueOf()
        this.filter.end_absolute = endDate.endOf('day').valueOf()
        this.filter.metrics.map(metric => {
          metric.aggregators[0].sampling = {
            value: '1',
            unit: 'hours',
          };
          metric.tags.workspaceId = [this.counsellor.workspaceId]
          return metric
        })
      }
      else if (isMoreThanAMonth(this.filter.start_absolute, this.filter.end_absolute)) {
        const stateDate = moment(this.filter.start_absolute)
        const endDate = moment(this.filter.end_absolute)
        this.filter.start_absolute = stateDate.startOf('month').valueOf()
        this.filter.end_absolute = endDate.endOf('month').valueOf()
        this.filter.metrics.map(metric => {
          metric.aggregators[0].sampling = {
            value: '1',
            unit: 'months',
          };
          metric.tags.workspaceId = [this.counsellor.workspaceId]
          return metric
        })
      }
      else {
        const stateDate = moment(this.filter.start_absolute)
        const endDate = moment(this.filter.end_absolute)
        this.filter.start_absolute = stateDate.startOf('day').valueOf()
        this.filter.end_absolute = endDate.endOf('day').valueOf()
        this.filter.metrics.map(metric => {
          metric.aggregators[0].sampling = {
            value: '1',
            unit: 'days',
          };
          metric.tags.workspaceId = [this.counsellor.workspaceId]
          return metric
        })
      }
      const params = this.filter;
      Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);
      // console.log("PARAM " + JSON.stringify(params))
      await this.fetchPieCalls(params);
      // console.log({casssses: this.pieCalls})
    },
    async formatGraphData() {
      this.data = {
        labels: [],
        series: []
      }
      this.options = {
          labelOffset: 70,
          labelDirection: 'explode',
          plugins: [
            Chartist.plugins.tooltip(),
            ChartistTooltip(),
            Chartist.plugins.legend({
              position: 'bottom',
            })
          ]
      }
      this.pieCalls.queries.forEach(query => {
        query.results.forEach((result, idx) => {
          switch (result.name) {
            case 'TotalCalls':
              if (this.tag === 'agentId') {
                if (result.group_by[0].group.agentId === '') {
                  this.data.labels[idx] = 'Unassigned*';
                } else {
                  const agent = this.counsellorList.find(c => c.uuid === result.group_by[0].group[this.tag])
                  agent ? this.data.labels[idx] = agent.name : this.data.labels[idx] = result.group_by[0].group[this.tag]
                }
              } else {
                if (result.group_by) this.data.labels[idx] = result.group_by[0].group[this.tag]
              }
              result.values.forEach((value, idxx) => {
                idxx === 0 ? this.data.series[idx] = value[1] : this.data.series[idx] += value[1]
              })
              break;
            default:
              break;
          }
        })
        //console.log({calldatadatadatadata: JSON.stringify(this.data)})
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .menu.dropdown {
    margin-bottom: 15px
  }
  .date-range-picker {
    z-index: 1;
  }
</style>


