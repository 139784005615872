<template>
  <div class="col-6">
    <card class="card" title="Overview" subTitle="Update Information about the case">
      <div class="row">
        <div class="col-md-12">
          <fg-input-dropdown type="text"
                             label="LGA Of Occurrence"
                             placeholder="Nature of Violence"
                             :error="error.lgaOfOccurrence"
                             :disabled="true"
                             :options="lgaOfOccurrenceOptions"
                             v-model="generalInfo.lgaOfOccurrence">
          </fg-input-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <fg-input-dropdown type="text"
                             label="Case Manager"
                             placeholder="Case Manager"
                             :error="error.caseManagerId"
                             :disabled="true"
                             :options="caseManagerOptions"
                             v-model="caseManager">
          </fg-input-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <fg-input-dropdown type="text"
                             label="Referred By"
                             :error="error.referredBy"
                             :disabled="true"
                             :options="referredByOptions"
                             v-model="generalInfo.referredBy">
          </fg-input-dropdown>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <fg-input-dropdown type="text"
                             label="Nature"
                             placeholder="Nature of Violence"
                             :error="error.nature"
                             :disabled="true"
                             :options="natureOptions"
                             v-model="generalInfo.nature">
          </fg-input-dropdown>
        </div>
      </div>

      <p-button type="success"
                style="margin-left: auto; display: block;"
                round
                :disabled="true"
                :loading="isUpdatingCaseInfo"
                @click.native.prevent="submitCaseInfoUpdate">
        Update Case Information
      </p-button>
    </card>
  </div>
</template>
<script>

import {
  mapState,
  mapActions,
} from 'vuex';
import { getCounsellor } from "@/utils/auth";
import swal from "sweetalert";
import router from "@/router";
import {updateCaseOverviewInfo} from "@/utils/api/cases";

export default {
  data() {
    return {
      caseManager: null,
      config: {
        format: '',
        useCurrent: false,
        showClear: true,
        showClose: true,
      },
      generalInfo: {
        lgaOfOccurrence: '',
        nature: '',
        referredBy: '',
        status: 'Ongoing',
      },
      referredByOptions: [
        {key: 0, value: "Social Media", text: "Social Media"},
        {key: 1, value: "Neighbour", text: "Neighbour"},
        {key: 2, value: "Friend", text: "Friend"},
        {key: 3, value: "TV Adverts", text: "TV Adverts"},
        {key: 4, value: "Billboard Poster", text: "Billboard Poster"},
        {key: 5, value: "Community Awareness", text: "Community Awareness"},
        {key: 6, value: "Others", text: "Others"}
      ],
      natureOptions: [
        {key: 0, value: "Rape", text: "Rape"},
        {key: 1, value: "Sexual Assault", text: "Sexual Assault"},
        {key: 3, value: "Sexual Harassment", text: "Sexual Harassment"},
        {key: 4, value: "Physical Assault", text: "Physical Assault"},
        {key: 5, value: "Denial of Resources", text: "Denial of Resource"},
        {key: 6, value: "Child Abuse", text: "Child Abuse"},
        {key: 7, value: "Defilement", text: "Defilement"},
        {key: 8, value: "Stalking", text: "Stalking"},
        {key: 9, value: "Revenge Porn (Releasing of Nudes)", text: "Revenge Porn (Releasing of Nudes)"},
        {key: 10, value: "Custody & Access", text: "Custody & Access"},
        {key: 11, value: "Verbal Abuse", text: "Verbal Abuse"},
        {key: 12, value: "Emotional Abuse", text: "Emotional Abuse"},
        {key: 13, value: "Non GBV", text: "Non GBV"},
        {key: 14, value: "Enquiry", text: "Enquiry"}
      ]
    };
  },
  props: {
    updateForm: {
      type: Function,
      required: true,
    },
    formTab: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: false,
    },
    error: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState('state', [
      'stateLga'
    ]),
    ...mapState('auth', [
      'counsellorManagers'
    ]),
    ...mapState("cases", ["isUpdatingCaseInfo"]),
    lgaOfOccurrenceOptions() {
      return this.stateLga.map((v, idx) => {
        return {
          key: idx,
          value: v,
          text: v,
        };
      });
    },
    caseManagerOptions() {
      return this.counsellorManagers.map((v, idx) => {
        return {
          key: idx,
          value: v.id.toString(),
          text: `${v.name} - ${v.role}`,
        };
      });
    },
  },
  watch: {
    caseManager(newValue) {
      const manager = this.counsellorManagers.find(m => (m.id.toString() === newValue));
      this.generalInfo.caseManagerId = parseInt(newValue, 10);
      //console.log({manager, newValue, caseManagerOptions: this.caseManagerOptions });
    },
    generalInfo: {
      deep: true,
      handler(newValue) {
        //console.log("generalInfo watcher ", JSON.stringify(newValue));
        this.updateForm(4, newValue);
      },
    },
  },
  async mounted() {
    const counsellor = getCounsellor();
    await this.fetchStateLga({stateIso2: counsellor.stateIso});
    await this.fetchCounsellorManagers({ workspaceId: counsellor.workspaceId });
    this.generalInfo = {
      ...this.generalInfo,
      ...this.data,
      dateOfOccurrence: new Date(this.data.dateOfOccurrence || new Date()),
      workspaceId: counsellor.workspaceId,
      stateIso2: counsellor.stateIso,
      callHandlerId: counsellor.counsellorId,
      callHandlerName: counsellor.name
    };
    //console.log(this.counsellorManagers);
    await this.fetchStateLga({stateIso2: counsellor.stateIso});
    this.caseManager = counsellor.counsellorId.toString();
    //console.log({counsellor, generalInfo: this.generalInfo})
  },
  methods: {
    ...mapActions('state', [
      'fetchStateLga',
    ]),
    ...mapActions('auth', [
      'fetchCounsellorManagers',
    ]),
    ...mapActions("cases", ["updateCaseOverviewInfo"]),
    async submitCaseInfoUpdate() {
      try {
        const results = await swal({
          title: "Update Case Information",
          text: `Are you sure you want to update case information?`,
          buttons: {
            cancel: true,
            confirm: true,
          },
        })
        if (results) {
          const counsellor = getCounsellor();
          const params = {
            ...this.generalInfo,
            caseId: Number(this.$route.params.id),
            counsellorId: counsellor.counsellorId,
            workspaceId: counsellor.workspaceId
          };
          console.log("ABOUT TO SAVE updateCaseInfo  with param " + JSON.stringify(params));
          await this.updateCaseOverviewInfo(params);
          this.$notify({
            message: 'Case Information Updated Successfully',
            icon: "ti-check-box",
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success'
          });
          await router.push({path: `/reports/details/${this.$route.params.id}`})
        }
        else {
          this.$notify({
            message: 'Cancelled Case Overview information was not updated :)',
            icon: "ti-alert",
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'warning'
          });
        }
      } catch(ex) {
        console.log('error', ex);
        this.$notify({
          message: 'Error Updating Case Overview, please try again late',
          icon: "ti-alert",
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      }
    }
  }
};
</script>
<style>
</style>
