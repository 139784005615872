import { fetchStateLgaData } from '@/utils/api/state';

const defaultState = {
    stateLga: [],
    isFetchingStateLga: false,
    isDownloadingStateLga: false,

};

const mutations = {
    setIsFetchingStateLga(state, status) {
        state.isFetchingStateLga = status;
    },
    setIsDownloadingStateLga(state, status) {
        state.isDownloadingStateLga = status;
    },
    setStateLga(state, stateLga) {
        state.stateLga = stateLga;
    },
};

const actions = {
    async fetchStateLga({ commit }, data) {
        try {
            commit('setIsFetchingStateLga', true);
            const results = await fetchStateLgaData(data);
            commit('setStateLga', results);
            commit('setIsFetchingStateLga', false);
        } catch (ex) {
            commit('setIsFetchingStateLga', false);
            throw ex;
        }
    },
};

export default {
    namespaced: true,
    state: defaultState,
    mutations,
    actions,
};
