<template>
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-6" v-if="formTab === '1'">
          <card class="card" title="Please choose the type of reporter.">
            <div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="false"
                  v-model="isSurvivor"
                />
                <label class="form-check-label" for="exampleRadios1">Mandated Reporter</label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  value="true"
                  v-model="isSurvivor"
                />
                <label class="form-check-label" for="exampleRadios2">Survivor</label>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <form class="col-12" @submit.prevent>
      <div class="row">
        <case-form1
          :error="error.form1"
          :formTab="formTab"
          :data="formData.reporterInfo"
          :updateForm="updateForm"
          v-if="(formTab === '1') && isSurvivor === 'false'"
        />
        <case-form2
          :error="error.form2"
          :formTab="formTab"
          :data="formData.survivorInfo"
          :updateForm="updateForm"
          v-if="(formTab === '1')"
        />
        <case-form3
          :error="error.form3"
          :formTab="formTab"
          :data="formData.perpetratorInfo"
          :updateForm="updateForm"
          v-if="formTab === '2'"
        />
        <case-form4
          :error="error.form4"
          :formTab="formTab"
          :data="formData"
          :updateForm="updateForm"
          v-if="formTab === '2'"
        />
      </div>
      <div class="row">
        <div class="col-6">
          <p-button
            type="info"
            v-if="formTab === '2'"
            round
            @click.native.prevent="formTab = '1'"
          >Back</p-button>
        </div>
        <div class="col-6">
          <p-button
            type="info"
            v-if="formTab === '1'"
            style="margin-left: auto; display: block;"
            round
            @click.native.prevent="moveToNextTab"
          >Next</p-button>
          <p-button
            type="success"
            style="margin-left: auto; display: block;"
            v-if="formTab === '2'"
            round
            @click.native.prevent="completeFormSubmission"
          >Complete Submission</p-button>
        </div>
        <div class="col-12">
          <div class="border-top my-3"></div>
        </div>
        <div class="col-6">
          <card class="card" title="Referral Agency" subTitle="Select the LGA of occurrence to find a referral Agency">
            <div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <fg-input-dropdown
                      type="text"
                      label="LGA Of Occurrence"
                      :options="lgaOfOccurrenceOptions"
                      v-model="lgaCallOptions"
                    ></fg-input-dropdown>
                    <table class="table">
                        <thead>
                            <th>Category</th>
                            <th>Address</th>
                            <th>Name</th>
                            <th>Contacts</th>
                        </thead>
                        <tbody>
                            <tr v-if="isFetchingReferralAgencies">
                                <td colspan="4" style="text-align: center;"><i class="fa fa-spinner fa-spin fa-fw fa-2x"></i></td>
                            </tr>
                            <tr v-else-if="!Object.keys(referralAgencies).length">
                            <td colspan="4" style="text-align: center;">No Referral Agencies Found</td>
                            </tr>
                            <tr v-else v-for="(item, idx) in referralAgencies" :key="idx">
                            <slot :row="item[0]">
                                <td><b>{{item[0].agencyCategory}}</b></td>
                                <td>{{item[0].address ? item[0].address : 'N/A'}}</td>
                                <td>{{item[0].name ? item[0].name : 'N/A'}}</td>
                                <td v-if="item[0].contacts && item[0].contacts.length === 0">
                                   No Contacts Found
                                </td>
                                <td v-else>
                                    <div v-for="(contact, idxx) in item[0].contacts" :key="idxx">
                                        <p class="contact-number" v-on:click="callReferralAgency(contact)">
                                            <b>{{contact.name ? `${contact.name} - ` : ''}}</b>{{contact.phone}}
                                        </p>
                                    </div>
                                </td>
                            </slot>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
         <div class="col-6">
          <card class="card" title="Please Add any notes here.">
            <div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Notes</label>
                    <textarea
                      rows="10"
                      class="form-control border-input"
                      placeholder="Add notes"
                      v-model="formData.notes"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import swal from "sweetalert";
import { EventBus } from "@/utils";
import { getCounsellor } from "@/utils/auth";
import CaseForm1 from "./Form1.vue";
import CaseForm2 from "./Form2.vue";
import CaseForm3 from "./Form3.vue";
import CaseForm4 from "./Form4.vue";
export default {
  data() {
    return {
     counsellor: null,
      formTab: "1",
      isSurvivor: "false",
      error: {
        form1: {
          age: null,
          gender: null,
          name: null,
          phone: null,
          type: null,
        },
        form2: {
          age: null,
          gender: null,
          address: null,
          employmentStatus: null,
          maritalStatus: null,
          name: null,
          phone: null,
        },
        form3: {
          address: null,
          ageRange: null,
          employmentStatus: null,
          gender: null,
          name: null,
          phone: null,
          relationship: null,
        },
        form4: {
          callHandlerName: null,
          dateOfOccurrence: null,
          lgaOfOccurrence: null,
          nature: null,
          referredBy: null,
          status: null,
          stateIso2: null,
          caseManagerId: null,
          notes: null,
        },
      },
      formData: {
        notes: "",
      },
      lgaCallOptions: null,
    };
  },
  computed: {
    ...mapState("state", ["stateLga"]),
    ...mapState("auth", ["referralAgencies", "isFetchingReferralAgencies"]),
    lgaOfOccurrenceOptions() {
      return this.stateLga.map((v, idx) => {
        return {
          key: idx,
          value: v,
          text: v,
        };
      });
    },
    referralAgency() {},
  },
  watch: {
    lgaCallOptions(newValue) {
      if (newValue) {
          this.formData.lgaOfOccurrence = newValue;
          this.fetchReferralAgencies({
              workspaceId: this.counsellor.workspaceId,
              lgaOfOccurrence: newValue,
          });
      }
    },
  },
  components: {
    CaseForm1,
    CaseForm2,
    CaseForm3,
    CaseForm4,
  },
  async mounted() {
    this.counsellor = getCounsellor();
    await this.fetchStateLga({ stateIso2: this.counsellor.stateIso });
    // console.log({stateLga: this.stateLga})
  },
  methods: {
    ...mapActions("cases", ["submitCase"]),
    ...mapActions("state", ["fetchStateLga"]),
    ...mapActions("auth", ["fetchReferralAgencies"]),
    updateForm(formNumber, data) {
      switch (formNumber) {
        case 1:
          this.formData.reporterInfo = data;
          break;
        case 2:
          this.formData.survivorInfo = data;
          break;
        case 3:
          this.formData.perpetratorInfo = data;
          break;
        case 4:
          const dateOfOccurrence = new Date(data.dateOfOccurrence).getTime();
          this.formData = {
            ...this.formData,
            ...data,
            dateOfOccurrence,
            status: "Ongoing",
          };
          console.log({ formData: this.formData, data });
          break;
        default:
          break;
      }
      // console.log(this.formData)
    },
    validateForm(formNumber) {
      let result = true;
      switch (formNumber) {
        case 1:
          if (this.formData.reporterInfo.type !== "Survivor") {
            Object.keys(this.error.form1).map((key) => {
              if (
                key === "age" ||
                key === "gender" ||
                key === "name" ||
                key === "phone"
              ) {
                if (
                  !this.formData.reporterInfo[key] ||
                  this.formData.reporterInfo[key] === ""
                ) {
                  this.error.form1[key] = "Please fill in this field";
                  console.log(key, result);
                  result = false;
                } else {
                  this.error.form1[key] = null;
                }
              }
            });
          }
          console.log(Object.keys(this.error.form1), result);
          break;
        case 2:
          console.log(Object.keys(this.error.form2));
          Object.keys(this.error.form2).map((key) => {
            if (
              key === "age" ||
              key === "address" ||
              key === "employmentStatus" ||
              key === "maritalStatus" ||
              key === "name" ||
              key === "phone"
            ) {
              if (
                !this.formData.survivorInfo[key] ||
                this.formData.survivorInfo[key] === ""
              ) {
                this.error.form2[key] = "Please fill in this field";
                console.log(key, result);
                result = false;
              } else {
                this.error.form2[key] = null;
              }
            }
          });
          break;
        case 3:
          console.log(Object.keys(this.error.form3));
          Object.keys(this.error.form3).map((key) => {
            if (
              key === "ageRange" ||
              key === "gender" ||
              key === "name" ||
              key === "relationship"
            ) {
              if (
                !this.formData.perpetratorInfo[key] ||
                this.formData.perpetratorInfo[key] === ""
              ) {
                console.log(key, result);
                this.error.form3[key] = "Please fill in this field";
                result = false;
              } else {
                this.error.form3[key] = null;
              }
            }
          });
          break;
        case 4:
          console.log(Object.keys(this.error.form4));
          Object.keys(this.error.form4).map((key) => {
            if (
              key === "callHandlerName" ||
              key === "dateOfOccurrence" ||
              key === "lgaOfOccurrence" ||
              key === "nature" ||
              key === "stateIso2" ||
              key === "caseManagerId"
            ) {
              if (!this.formData[key] || this.formData[key] === "") {
                console.log(key, result);
                this.error.form4[key] = "Please fill in this field";
                result = false;
              } else {
                this.error.form4[key] = null;
              }
            }
          });
          break;
        default:
          break;
      }
      return result;
    },
    moveToNextTab() {
      if (this.isSurvivor === "true") {
        this.formData.reporterInfo = {
          type: "Survivor",
        };
      }
      if (this.validateForm(1) && this.validateForm(2)) this.formTab = "2";
    },
    callReferralAgency(contact) {
      console.log({contact})
      if (this.counsellor.role === 'Administrator' || this.counsellor.role === 'Call Handler') {
          EventBus.$emit('makeCall', contact.phone);
          window.scrollTo(0,0)
      }
    },
    completeFormSubmission() {
      try {
        const { $router, submitCase, formData } = this;
        if (this.validateForm(3) && this.validateForm(4)) {
          swal({
            title: "Complete Submission",
            text: "Are You sure you want to complete this case submission?",
            buttons: {
              cancel: true,
              confirm: true,
            },
          }).then(function (results) {
            if (results) {
              // second variant
              // console.log(JSON.stringify(formData))
              submitCase(formData);
              $router.push("/reports");
              this.$notify({
                message: 'Case Submitted Successfully',
                icon: "ti-check-box",
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'success'
              });
            } else {
              // second variant
            }
          });
        }
      } catch (ex) {
        console.log("erorrrrr", { ex });
        this.$notify({
          message: 'Error submitting case',
          icon: "ti-alert",
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'danger'
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.contact-number{
  color: #68B3C8;
  cursor: pointer;
  &:hover{
    color: #3091B2;
  }
}
</style>
