<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard" name="Dashboard" icon="ti-panel"/>
        <sidebar-link v-if="counsellor.role === 'Administrator' || counsellor.role === 'Call Handler'"  to="/create" name="New Case" icon="ti-plus"/>
        <sidebar-link to="/reports" name="Reports" icon="ti-bar-chart-alt"/>
        <sidebar-link v-if="counsellor.role !== 'Call Handler'" to="/cases" name="My Cases" icon="ti-menu-alt"/>
        <sidebar-link to="/calls/logs" name="Call Logs" icon="ti-headphone-alt"/>
        <sidebar-link to="/analytics" name="Analytics" icon="ti-pie-chart"/>
        <sidebar-link to="/settings" name="Settings" icon="ti-settings"/>
        <!-- <sidebar-link to="/settings/password" name="Change Password" icon="ti-key"/>-->
        <!-- <sidebar-link to="/maps" name="Map" icon="ti-map"/> -->
        <!-- <sidebar-link to="/notifications" name="Notifications" icon="ti-bell"/> -->
        <!-- <sidebar-link to="/profile" name="Profile" icon="ti-user"/> -->
      </template>
      <mobile-menu>
        <li class="nav-item">
          <a class="nav-link">
            <i class="ti-panel"></i>
            <p>My Profile</p>
          </a>
        </li>
        <drop-down class="nav-item"
                   title="5 Notifications"
                   title-classes="nav-link"
                   icon="ti-bell">
          <a class="dropdown-item">Notification 1</a>
          <a class="dropdown-item">Notification 2</a>
          <a class="dropdown-item">Notification 3</a>
          <a class="dropdown-item">Notification 4</a>
          <a class="dropdown-item">Another notification</a>
        </drop-down>
        <li class="nav-item">
          <a class="nav-link">
            <i class="ti-settings"></i>
            <p>Settings</p>
          </a>
        </li>
        <li class="divider"></li>
      </mobile-menu>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import { getCounsellor } from "@/utils/auth";
export default {
  data() {
    return {
      counsellor: {}
    }
  },
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  },
  async mounted() {
    this.counsellor = getCounsellor();
    console.log(this.counsellor)
  }
};
</script>
