<template>
    <div class="col-12">
       <p-button v-if="this.$route.params.type" type="info"
              class="backBtn"
              round
              @click.native.prevent="$router.push('/cases/list')">
              Back
        </p-button>
        <p-button v-else type="info"
              class="backBtn"
              round
              @click.native.prevent="$router.push('/reports')">
              Back
        </p-button>
        <p-button type="info"
              class="backBtn"
              style="float: right;"
              round
              @click.native.prevent="$router.push({ path: `/update/${reportDetails.id}`, params: { data: reportDetails } })">
              <i class="fa fa-edit"  style="font-size: 18px;"/>
        </p-button>
        <div class="row">
            <div class="col-6">
                <card title="Overview" subTitle="General information on the case">
                        <p><b>Date of Occurrence: </b>{{reportDetails.dateOfOccurrence ? reportDetails.dateOfOccurrence : 'N/A' }}</p>
                        <p><b>LGA of Occurrence: </b>{{reportDetails.lgaOfOccurrence ? reportDetails.lgaOfOccurrence : 'N/A' }}</p>
                        <p><b>Nature: </b>{{reportDetails.nature ? reportDetails.nature : 'N/A' }}</p>
                        <!--<p><b>Actions Taken: </b>{{reportDetails.actionsTaken ? reportDetails.actionsTaken : 'N/A' }}</p>-->
                       <ul>
                         <li :style="{'font-size': '18px'}" v-for="item in reportDetails.notes">
                           <span :style="{'font-size': '16px'}">
                             {{ item | formatNote }}
                           </span>
                         </li>
                       </ul>
                    </card>
                </div>
            <div class="col-6">
                <card title="Case Manager Details" subTitle="Information on the manager handling the case">
                        <p><b>Name: </b>{{reportDetails.caseManagerName ? reportDetails.caseManagerName : 'N/A' }}</p>
                        <p><b>Gender: </b>{{reportDetails.caseManagerGender ? reportDetails.caseManagerGender : 'N/A' }}</p>
                        <p><b>Email: </b>{{reportDetails.caseManagerEmail ? reportDetails.caseManagerEmail : 'N/A' }}</p>
                        <p><b>Phone Number: </b>{{reportDetails.caseManagerPhone | formatPhoneNumber }}</p>
                        <p><b>Current Role: </b>{{reportDetails.caseManagerCurrentRole ? reportDetails.caseManagerCurrentRole : 'N/A' }}</p>
                        <p><b>Qualifications: </b>{{reportDetails.caseManagerQualification ? reportDetails.caseManagerQualification : 'N/A' }}</p>
                    </card>
            </div>
            <div class="col-6">
              <card title="Survivor Details" subTitle="Information on the Survivor">
                <p><b>Name: </b>{{reportDetails.survivorName ? reportDetails.survivorName : 'N/A' }}</p>
                <p><b>Address: </b>{{reportDetails.survivorAddress ? reportDetails.survivorAddress : 'N/A' }}</p>
                <p><b>Marital Status: </b>{{reportDetails.survivorMaritalStatus ? reportDetails.survivorMaritalStatus : 'N/A' }}</p>
                <p><b>Age: </b>{{reportDetails.survivorAge ? reportDetails.survivorAge : 'N/A' }}</p>
                <p><b>Gender: </b>{{reportDetails.survivorGender ? reportDetails.survivorGender : 'N/A' }}</p>
                <p><b>Employment Status: </b>{{reportDetails.survivorEmploymentStatus ? reportDetails.survivorEmploymentStatus : 'N/A' }}</p>
                <p><b>Phone Number: </b>{{reportDetails.survivorPhone ? reportDetails.survivorPhone : 'N/A' }}</p>
              </card>
            </div>
            <div class="col-6">
                <card title="Perpetrator Details" subTitle="Information on the alleged perpetrator">
                        <p><b>Name: </b>{{reportDetails.perpetratorName ? reportDetails.perpetratorName : 'N/A' }}</p>
                        <p><b>Age Range: </b>{{reportDetails.perpetratorAgeRange ? reportDetails.perpetratorAgeRange : 'N/A' }}</p>
                        <p><b>Gender: </b>{{reportDetails.perpetratorGender ? reportDetails.perpetratorGender : 'N/A' }}</p>
                        <p><b>Phone Number: </b>{{reportDetails.perpetratorPhone ? reportDetails.perpetratorPhone : 'N/A' }}</p>
                        <p><b>Relationship: </b>{{reportDetails.perpetratorType ? reportDetails.perpetratorType : 'N/A' }}</p>
                        <p><b>Employment Status: </b>{{reportDetails.perpetratorEmploymentStatus ? reportDetails.perpetratorEmploymentStatus : 'N/A' }}</p>
                        <p><b>Address: </b>{{reportDetails.perpetratorAddress ? reportDetails.perpetratorAddress : 'N/A' }}</p>
                    </card>
            </div>
            <div class="col-6">
                <card title="Reporter Details" subTitle="Information on the Reporter">
                    <p><b>Type: </b>{{reportDetails.reporterType ? reportDetails.reporterType : 'N/A' }}</p>
                    <p><b>Name: </b>{{reportDetails.reporterName ? reportDetails.reporterName : 'N/A' }}</p>
                    <p><b>Gender: </b>{{reportDetails.reporterGender ? reportDetails.reporterGender : 'N/A' }}</p>
                    <p><b>Age: </b>{{reportDetails.reporterAge ? reportDetails.reporterAge : 'N/A' }}</p>
                    <p><b>Phone Number: </b>{{reportDetails.reporterPhone ? reportDetails.reporterPhone : 'N/A' }}</p>
                </card>
            </div>
          <div class="col-6">
            <card title="Case Activities" subTitle="Activities that happened for this case">
              <ul>
                <li :style="{'font-size': '18px'}" v-for="item in reportDetails.activities">
                  <span :style="{ 'font-size': '16px'}">
                    {{ item | formatActivity }}
                  </span>
                </li>
              </ul>
            </card>
          </div>
          <div class="col-6">
            <card title="Call Handler Details" subTitle="Information on the Call Handler">
              <p><b>Name: </b>{{reportDetails.callHandlerName ? reportDetails.callHandlerName : 'N/A' }}</p>
              <p><b>Gender: </b>{{reportDetails.callHandlerGender ? reportDetails.callHandlerGender : 'N/A' }}</p>
              <p><b>Email: </b>{{reportDetails.callHandlerEmail ? reportDetails.callHandlerEmail : 'N/A' }}</p>
              <p><b>Phone Number: </b>{{reportDetails.callHandlerPhone | formatPhoneNumber }}</p>
              <p><b>Current Role: </b>{{reportDetails.callHandlerCurrentRole ? reportDetails.callHandlerCurrentRole : 'N/A' }}</p>
              <p><b>Qualifications: </b>{{reportDetails.callHandlerQualification ? reportDetails.callHandlerQualification : 'N/A' }}</p>
            </card>
          </div>
        </div>
        <div class="row" v-if="this.$route.params.type">
            <div class="col-12"><hr/></div>
        </div>
        <div class="row" v-if="this.$route.params.type">
            <form class="col-6" @submit.prevent v-if="isClosingCaseForm">
                <card title="Close Case" subTitle="Complete this form to close the case">
                    <div class="row">
                        <div class="col-6">
                            <fg-input-dropdown type="text"
                                label="Case Status"
                                :options="caseStatusOptions"
                                v-model="caseStatus">
                            </fg-input-dropdown>
                        </div>
                        <div class="col-6">
                            <fg-input-dropdown type="text"
                                label="Agency"
                                :options="agencyOptions"
                                v-model="agency">
                            </fg-input-dropdown>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <fg-input type="text"
                                    label="Officer Name"
                                    v-model="officer">
                            </fg-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <fg-input type="text"
                                    label="Phone Number"
                                    v-model="phone">
                            </fg-input>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Notes</label>
                        <textarea rows="4" class="form-control border-input"
                            placeholder="Add notes"
                            v-model="notes">
                        </textarea>
                    </div>
                    <p-button type="warning"
                                round
                                @click.native.prevent="isClosingCaseForm = false">
                        Back
                    </p-button>
                     <p-button type="success"
                                round
                                :loading="isClosingCase"
                                @click.native.prevent="closeMyCase">
                        Submit
                    </p-button>
                </card>
            </form>
            <form class="col-6" @submit.prevent v-if="!isClosingCaseForm">
                <card title="Notes" subTitle="Add additional notes related to case">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <textarea rows="4" class="form-control border-input"
                                    placeholder="Add notes"
                                    v-model="notes">
                                </textarea>
                            </div>
                        </div>
                    </div>
                     <p-button type="success"
                                style="margin-left: auto; display: block;"
                                round
                                :loading="isSubmittingCaseNote"
                                @click.native.prevent="saveMyNote">
                        Submit
                    </p-button>
                </card>
            </form>
            <div class="col-6">
                <card title="Case Actions" subTitle="Change case status" v-if="!isClosingCaseForm">
                    <div class="row" v-if="this.$route.params.type === 'assigned' || this.$route.params.type === 'accepted'">
                        <div class="col-12">
                            <fg-input-dropdown type="text"
                                label="Transfer to"
                                :loading="isTransferringCase"
                                placeholder="Pick manager"
                                :options="caseManagerOptions"
                                v-model="caseManager">
                            </fg-input-dropdown>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5" v-if="this.$route.params.type === 'transferred'">
                            <a :loading="isAcceptingCase" class="btn btn-round btn-info" v-on:click="acceptMyCase">Accept Case</a>
                        </div>
                        <div class="col-4 side-actions"
                            v-if="!isClosingCaseForm && (this.$route.params.type === 'accepted')">
                            <a class="btn btn-round btn-danger" v-on:click="isClosingCaseForm = true">Close Case</a>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import {
    mapState,
    mapActions,
} from 'vuex';
import { getCounsellor } from "@/utils/auth";
export default {
    data() {
        return {
            counsellor: null,
            notes: null,
            caseManager: null,
            isClosingCaseForm: false,
            caseStatus: null,
            agency: null,
            officer: null,
            phone: null,
            caseStatusOptions: [{
                key: 0,
                value: 'Ongoing',
                text: 'Ongoing',
            }, {
                 key: 1,
                value: 'Pending',
                text: 'Pending',
            }, {
                 key: 2,
                value: 'Struck out',
                text: 'Struck out',
            }, {
                 key: 3,
                value: 'Settled',
                text: 'Settled',
            }, {
                 key: 4,
                value: 'Prosecution',
                text: 'Prosecution',
            }],
            agencyOptions: [{
                key: 0,
                value: 'Police',
                text: 'Police',
            }, {
                 key: 1,
                value: 'Army',
                text: 'Army',
            }, {
                 key: 2,
                value: 'Human Rights',
                text: 'Human Rights',
            }]
        };
    },
     computed: {
        ...mapState('cases', [
            'reportDetails', 'isFetchingReportDetails', 'isAcceptingCase', 'isSubmittingCaseNote', 'isClosingCase', 'isTransferringCase'
        ]),
        ...mapState('auth', [
            'counsellorManagers'
        ]),
        caseManagerOptions() {
            return this.counsellorManagers.map((v, idx) => {
                return {
                    key: idx,
                    value: v.id.toString(),
                    text: `${v.name} - ${v.role}`,
                };
            });
        },
    },
    watch: {
        caseManager(newValue) {
            const manager = this.counsellorManagers.find(m => (m.id.toString() === newValue));
            this.transferMyCase(manager);
        },
        isClosingCaseForm(newValue) {
            if (newValue) {
                this.$notify({
                    message: 'Complete the form below to close this case',
                    icon: "ti-info-alt",
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'info'
                });

            }
        }
    },
    filters: {
        formatDate(date) {
            let result = 'N/A';
            if (date) result = moment(date).format('MMMM Do YYYY, h:mm:ss a')
            return result;
        },
        formatNotes(notes) {
            let result = '';
            if (notes) {
                notes.forEach(note => {
                    const info = JSON.parse(note);
                    result += `\n${info.note} by ${info.authorName}\n`
                })
            } else {
                result = 'N/A';
            }
            return result;
        },
      formatNote(note) {
        let result = '';
        if (note) {
          const info = JSON.parse(note);
          result += `\n${info.note} by ${info.authorName}\n`
        } else {
          result = 'N/A';
        }
        return result;
      },
      formatActivity(activity) {
        let result = '';
        switch (activity.activity) {
          case 'CaseAddedAndAssigned':
            result += `\n${activity.requesterName} adds and assigned this case to ${activity.assigneeName} on ${activity.created.substring(0, activity.created.length - 6)}\n`
            break;
          case 'CaseNoteAdded':
            result += `\n${activity.requesterName} added a note to this case on ${activity.created.substring(0, activity.created.length - 6)}\n`
            break;
          case 'CaseStatusChange':
            result += `\n${activity.requesterName} updated status of this case on ${activity.created.substring(0, activity.created.length - 6)}\n`
            break;
          case 'CaseSurvivorInfoUpdated':
            result += `\n${activity.requesterName} updated survivor information on ${activity.created.substring(0, activity.created.length - 6)}\n`
            break;
          case 'CaseReporterInfoUpdated':
            result += `\n${activity.requesterName} updated reporter information on ${activity.created.substring(0, activity.created.length - 6)}\n`
            break;
          case 'CasePerpetratorInfoUpdated':
            result += `\n${activity.requesterName} updated alleged perpetrator information on ${activity.created.substring(0, activity.created.length - 6)}\n`
            break;
          case 'CaseOverviewInfoUpdated':
            result += `\n${activity.requesterName} updated the case overview on ${activity.created.substring(0, activity.created.length - 6)}\n`
            break;
          default:
            result = 'N/A';
            break;
        }
        return result;
      },
        formatPhoneNumber(phoneNumbers) {
            let result = '';
            if (phoneNumbers) {
                phoneNumbers.forEach(phoneNumber => {
                    result += `${phoneNumber}`;
                    // if (phoneNumbers.length > 1) result += ', ';
                })
            } else {
                result = 'N/A';
            }
            return result;
        }
    },
    async mounted() {
        this.counsellor = getCounsellor();
        const params = {
            id: this.$route.params.id,
            workspaceId: this.counsellor.workspaceId
        }
        await this.fetchCounsellorManagers({ workspaceId: this.counsellor.workspaceId });
        await this.fetchReportDetails(params);
    },
    methods: {
        ...mapActions('cases', [
            'fetchReportDetails', 'acceptCase', 'closeCase', 'transferCase', 'submitCaseNote'
        ]),
        ...mapActions('auth', [
            'fetchCounsellorManagers',
        ]),
        async closeMyCase() {
            try {
                const params = {
                    workspaceId: this.counsellor.workspaceId,
                    caseId: this.reportDetails.id,
                    managerId: this.counsellor.counsellorId,
                    managerName: this.counsellor.name,
                    notes: this.notes,
                    caseStatus: this.caseStatus,
                    onwardReferralInfo: {
                        agency: this.agency,
                        officer: this.officer,
                        phone: this.phone,
                    }
                };
                await this.closeCase(params);
                this.$notify({
                    message: 'Case Closed',
                    icon: "ti-check-box",
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'success'
                });
                this.notes = null;
                this.agency = null;
                this.officer = null;
                this.phone = null;
                this.caseStatus = null;
                this.isClosingCaseForm = false;
                console.log('closeCase')
            } catch(ex) {
                console.log('error', ex);
            }
        },
        async transferMyCase(manager) {
            try{
                const params = {
                    transferToId: manager.id,
                    workspaceId: this.counsellor.workspaceId,
                    managerId: this.counsellor.counsellorId,
                    stateIso2: this.counsellor.stateIso,
                    caseId: this.reportDetails.id,
                };
                await this.transferCase(params);
                this.$notify({
                    message: `Case transferred to ${manager.name} - ${manager.role}`,
                    icon: "ti-check-box",
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'success'
                });
                this.fetchReportDetails({
                    id: this.$route.params.id,
                    workspaceId: this.counsellor.workspaceId
                });
                console.log('transferred to', manager)
            } catch(ex) {
                console.log('error', ex);
            }
        },
        async saveMyNote() {
            try{
                const params = {
                    caseId: this.reportDetails.id,
                    requesterId: this.counsellor.counsellorId,
                    requesterName: this.counsellor.name,
                    notes: this.notes
                };
                await this.submitCaseNote(params);
                this.$notify({
                    message: 'Note Saved',
                    icon: "ti-check-box",
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'success'
                });

                this.fetchReportDetails({
                    id: this.$route.params.id,
                    workspaceId: this.counsellor.workspaceId
                });
                console.log('saveNote')
            } catch(ex) {
                console.log('error', ex);
            }
        },
        async acceptMyCase() {
            try{
                const params = {
                    workspaceId: this.counsellor.workspaceId,
                    caseId: this.reportDetails.id,
                    managerId: this.counsellor.counsellorId
                };
                await this.acceptCase(params)
                this.$notify({
                    message: 'Case Accepted and moved to my assigned cases',
                    icon: "ti-check-box",
                    horizontalAlign: 'center',
                    verticalAlign: 'top',
                    type: 'success'
                });

                console.log('saveNote')
            } catch(ex) {
                console.log('error', ex);
            }
        }
    }
}
</script>
<style lang="scss">
  .backBtn{
    margin-bottom: 10px;
  }
  .notes{
      white-space: pre-line;
  }
  .side-actions{
    display: flex;
    align-items: flex-end;
    margin-left: auto;
    flex-direction: row;
    vertical-align: baseline;
    a{
        margin-bottom: 15px;
    }
  }
</style>
