<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">{{routeName}}</a>
      <button
        class="navbar-toggler navbar-burger"
        type="button"
        @click="toggleSidebar"
        :aria-expanded="$sidebar.showSidebar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
      </button>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" id="popoverBtn">
            <a
              :class="[{'btn': true,
              'btn-success': phoneBtnName === 'Available',
              'btn-warning': phoneBtnName === 'Away',
              'btn-danger': phoneBtnName === 'Offline'
              }]"
              data-toggle="popover"
              data-placement="top"
              v-if="counsellor.role === 'Administrator' || counsellor.role === 'Call Handler'"
            >
              <i class="ti-headphone-alt"></i>
              {{phoneBtnName}}
            </a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link" @click="logoutUser">
              <i class="ti-power-off"></i>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { getCounsellor, deleteAccessToken, isSignedIn } from "@/utils/auth";
import { EventBus } from "@/utils";

export default {
  data() {
    return {
      counsellor: null,
      phoneBtnName: "Offline",
      clientStatus: "Offline",
      popupTemplate: "",
      activeNotifications: false,
      outgoingPhoneNumber: null,
      incomingPhoneNumber: null,
      offlineStatus: false,
      refreshConnection: {
        count: 1,
        delay: 10000,
      },
    };
  },
  computed: {
    ...mapState("voiceClient", [
      "ws",
      "client",
      "isCreatingClient",
      "capabilityToken",
    ]),
    ...mapState('cases', ['callReports', 'isFetchingCallReports']),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  watch: {
    // popupTemplate(newValue) {}
  },
  created() {
    this.counsellor = getCounsellor();
  },
  async mounted() {
    this.popupTemplate = `
        <div class="row loading">
          <div class="col-12">
            <p><b>Refreshing</b></p>
          </div>
        </div>
    `;
    await this.initWs();
    const container = document.getElementById("popoverBtn");
    $(() => {
      $('[data-toggle="popover"]').popover({
        html: true,
        container,
        sanitize: false,
        content: this.getPopupContent,
      });
      $('[data-toggle="popover"]').on("shown.bs.popover", () => {
        if (
          this.popupTemplate.includes("makeCallForm") &&
          this.popupTemplate.includes("makeCallInput") &&
          this.popupTemplate.includes("statusOptions")
        ) {
          const makeCallForm  = document.getElementById("makeCallForm");
          const phoneInput    = document.getElementById("makeCallInput");
          const statusOptions = document.querySelectorAll("#statusOptions a");
          statusOptions.forEach((element) => {
            element.addEventListener("click", (e) => {
              this.updateClientStatus(e.target.innerHTML);
            });
          });
          makeCallForm.addEventListener("submit", (e) => {
            e.preventDefault();
            this.outgoingPhoneNumber = phoneInput.value;
            this.client.call(phoneInput.value);
            // client.call('08137572021');
          });
        } else if (
          this.popupTemplate.includes("answerCallBtn") &&
          this.popupTemplate.includes("rejectCallBtn")
        ) {
          const answerCallBtn = document.getElementById("answerCallBtn");
          const rejectCallBtn = document.getElementById("rejectCallBtn");
          rejectCallBtn.addEventListener("click", () => {
            this.client.hangup();
          });
          answerCallBtn.addEventListener("click", () => {
            this.client.answer();
          });
        } else if (this.popupTemplate.includes("endCallBtn")) {
          const endCallBtn = document.getElementById("endCallBtn");
          endCallBtn.addEventListener("click", () => {
            // console.log("endCallBtn handleCalling");
            this.client.hangup();
          });
        } else if (this.popupTemplate.includes("hangupBtn")) {
          const hangupBtn = document.getElementById("hangupBtn");
          hangupBtn.addEventListener("click", () => {
            // console.log("end call");
            this.client.hangup();
          });
        } else if (this.popupTemplate.includes("refreshBtn")) {
          const refreshBtn = document.getElementById("refreshBtn");
          refreshBtn.addEventListener("click", () => {
            this.handleRefresh();
          });
        }
      });
    });
  },
  beforeDestroy() {
    const params = {
      status: "Offline",
      type: "UpdateAgentStatus",
      agentId: this.capabilityToken.clientName,
    };
    // console.log({ updateClientStatus: params });
    this.ws.send(JSON.stringify(params));
    this.client.logout();
    this.ws.close();
    if (this.wsKeepaliveTimeoutId) {
      clearTimeout(this.wsKeepaliveTimeoutId);
    }
  },
  methods: {
    ...mapActions("voiceClient", [
      "initializeWs",
      "initializeClient",
      "saveCapabilityToken",
    ]),
    ...mapActions('cases', ['fetchCallReports']),
    getPopupContent() {
      return this.popupTemplate;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logoutUser() {
      deleteAccessToken();
      this.offlineStatus = true;
      this.$router.push("/user/login");
    },

    updateClientStatus(status) {
      // console.log({ status });
      const params = {
        status,
        type: "UpdateAgentStatus",
        agentId: this.capabilityToken.clientName,
      };
      // console.log({ updateClientStatus: params });
      this.ws.send(JSON.stringify(params));
      this.handleReset();
      if (status === "Offline") {
        this.offlineStatus = true;
        this.client.logout();
      }
    },
    async initWs() {
      await this.initializeWs({
        counsellorId: this.counsellor.counsellorId,
        isAgent: (this.counsellor.role === 'Administrator' || this.counsellor.role === 'Call Handler')
      });
      this.keepAlive();
      EventBus.$on("handleWsClose", () => this.handleWsClose());
      EventBus.$on("handleWsMessage", (data) => this.handleWsMessage(data));
    },
    async initVoiceClient() {
      try {
        // console.log({ capabilityToken: this.capabilityToken });
        const clientParams = {
          token: this.capabilityToken.token,
          // token: "ATCAPtkn_c2d7fefa506207e0ea5b584ce9b29a3a419a2df3c54540513ec75bd1df948a23",
          handleReady: () => this.handleReady(),
          handleNotReady: () => this.handleNotReady(),
          handleCalling: () => this.handleCalling(),
          handleIncomingCall: (params) => this.handleIncomingCall(params),
          handleCallAccepted: () => this.handleCallAccepted(),
          handleHangup: (hangupCause) => this.handleHangup(hangupCause),
          handleMissedCall: () => this.handleMissedCall(),
          handleOffline: () => this.handleOffline(),
          handleClosed: () => this.handleClosed(),
        };
        await this.initializeClient(clientParams);
        // console.log({ "client in topbar1": this.client });
      } catch (ex) {
        console.log("error in init voice client", ex);
      }
    },
    keepAlive() {
      if (this.ws === null || !this.ws) return;
      this.wsKeepaliveTimeoutId = setTimeout(this.keepAlive, 30000);
      let request = { type: "KeepAlive" };
      // console.log("keepalive");
      this.ws.send(JSON.stringify(request));
    },
    async handleWsMessage(json) {
      // const json = {
      //     "type": "AlertAgentWrapUp",
      //     "agentId": "c038o9o7aalg00fvtm2g",
      //     "status": "WrapUp",
      //     "wrapUpTimeInSec": 60
      // };
      // console.log({ handleWsMessage: json });
      switch (json.type) {
        case "CapabilityToken":
          await this.saveCapabilityToken(json);
          await this.initVoiceClient();
          break;
        case "AgentStatusUpdated":
          this.clientStatus = json.status;
          this.phoneBtnName = json.status;
          break;
        case "AlertAgentStatusChange":
          this.$notify({
            message: `Your status is now away. Change your status to available to receive calls`,
            icon: "ti-info",
            horizontalAlign: "center",
            verticalAlign: "top",
            timeout: 60000,
            type: "warning",
          });
          this.clientStatus = "Away";
          this.phoneBtnName = "Away";
          break;
        case "AlertAgentWrapUp":
          this.$notify({
            message: `Your Wrap up time is ${json.wrapUpTimeInSec} seconds`,
            icon: "ti-info",
            timeout: json.wrapUpTimeInSec,
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "warning",
          });
          // let tmp =2;
          //   setInterval(function(){
          //       let c=tmp--,m=(c/60)>>0,s=(c-m*60)+'';
          //       let message='Registration closes in '+m+':'+(s.length>1?'':'0')+s
          //       tmp!=0||(tmp=time);
          //       console.log({message})
          //   },1000);
          break;
        default:
          break;
      }
    },
    async handleWsClose() {
      if (this.refreshConnection.count < 10 && isSignedIn()) {
        this.$notify({
          message: `Refreshing Voice Client in ${
            this.refreshConnection.count * this.refreshConnection.delay * 0.001
          } seconds`,
          icon: "ti-info",
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "info",
        });
        setTimeout(async () => {
          this.client.hangup();
          this.client.logout();
          await this.initWs();
          // console.log(
          //   "refreshConnection.count * refreshConnection.delay",
          //   this.refreshConnection
          // );
          this.refreshConnection.count = +1;
        }, this.refreshConnection.delay);
        this.refreshConnection.count = +1;
      }
    },
    handleRefresh() {
      // console.log('handleRefresh',this.client, this.ws );
      location.reload();
      // if (this.client) {
      //   this.client.hangup();
      //   this.client.logout();
      // }
      // if (this.ws) {
      //   this.ws.close();
      // }
      // this.initWs();
      // // this.initVoiceClient();
      // this.popupTemplate = `
      //     <div class="row loading">
      //       <div class="col-12">
      //         <p><b>Refreshing</b></p>
      //       </div>
      //     </div>
      // `;
    },
    handleReset() {
      this.popupTemplate = `
        <form id="makeCallForm" class="row home">
          <div class="col-12">
          <p><b>Status: ${this.clientStatus}</b></p>
          </div>
          <div class="col-12 makeCallAction">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="e.g. +234711234567" id="makeCallInput">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="submit">Call</button>
              </div>
            </div>
          </div>
          <div class="col-12"><hr></div>
          <div class="col-12">
            <div class="dropdown">
              <a class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Change Status
              </a>
              <div class="dropdown-menu" id="statusOptions" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#">Available</a>
                <a class="dropdown-item" href="#">Away</a>
                <a class="dropdown-item" href="#">Offline</a>
              </div>
            </div>
          </div>
        </form>
      `;
      this.openPopup();
    },
    handleReady() {
      this.phoneBtnName = "Online";
      this.clientStatus = "Available";
      const params = {
        status: "Available",
        type: "UpdateAgentStatus",
        agentId: this.capabilityToken.clientName,
      };
      // console.log({ updateClientStatus: params, ws: this.ws });
      this.ws.send(JSON.stringify(params));
      this.$notify({
        message: "Virtual Phone is online",
        icon: "ti-check-box",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "success",
      });
      window.addEventListener("offline", (e) => {
        this.offlineStatus = true;
        this.phoneBtnName = "Offline";
        this.clientStatus = "Offline";
        this.client.logout();
        this.ws.close();
        this.$notify({
          message: "Internet Connection Lost",
          icon: "ti-close",
          timeout: 100000,
          horizontalAlign: "center",
          verticalAlign: "top",
          type: "warning",
        });
      });

      window.addEventListener("online", async (e) => {
        if (this.offlineStatus) {
          this.offlineStatus = false;
          this.$notify({
            message: "Internet Back Online",
            icon: "ti-check-box",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: "success",
          });
          setTimeout(() => location.reload(), 100);
        }
      });
      EventBus.$on("makeCall", (phone) => {
        this.outgoingPhoneNumber = phone;
        this.client.hangup();
        this.client.call(phone);
      });
      this.handleReset();
    },
    handleCalling() {
      this.popupTemplate = `
        <div class="row">
          <div class="col-12">
            <p><b>Making Outbound Call to ${this.outgoingPhoneNumber}</b></p>
          </div>
          <div class="col-12 iconActions">
            <span class="red icon"><img id="endCallBtn" src="/phone-reject.png"></span>
          </div>
        </div>
      `;
      this.openPopup();
    },
    async handleIncomingCall(params) {
      this.incomingPhoneNumber = params.from;
      this.$notify({
        message: `Incoming call from ${params.from}`,
        icon: "ti-headphone-alt",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "success",
      });
      this.popupTemplate = `
        <div class="row">
          <div class="col-12">
            <p><b>Incoming call from <br> ${this.incomingPhoneNumber}</b></p>
          </div>
          <div class="col-12 iconActions">
            <span class="green icon"><img id="answerCallBtn" src="/phone-answer.png"></span>
            <span class="red icon"><img id="rejectCallBtn" src="/phone-reject.png"></span>
          </div>
        </div>
      `;
      this.openPopup();
    },
    async handleCallAccepted() {
      this.popupTemplate = `
        <div class="row">
          <div class="col-12">
            <p><b>Ongoing Call</b></p>
          </div>
          <div class="col-12 iconActions">
            <span class="red icon"><img id="hangupBtn" src="/phone-reject.png"></span>
          </div>
        </div>
      `;
      if (this.incomingPhoneNumber) {
        // console.log(this.routeName);
        await this.fetchCallReports({
          workspaceId: this.counsellor.workspaceId,
          phoneNumber: this.incomingPhoneNumber
        });
        if (this.callReports.length > 0) {
          // console.log({fetchCallReports: this.fetchCallReports})
          this.$router.push('/reports/related')
        } else {
          if (this.routeName != "Add new case") {
            await this.$router.push({
              name: "add new case",
              params: { reporterInfo: { phone: this.incomingPhoneNumber } },
            });
          }
        }
        // console.log({ incomingPhoneNumber: this.incomingPhoneNumber });
        await EventBus.$emit("copyIncomingCallNo", this.incomingPhoneNumber);
        this.incomingPhoneNumber = null;
      }
      this.openPopup();
    },
    handleHangup(hangupCause) {
      this.handleReset();
    },
    handleMissedCall() {
      this.$notify({
        message: "Missed Call",
        icon: "ti-check-box",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "info",
      });
    },
    handleNotReady() {
      this.$notify({
        message: "Virtual Phone is offline.",
        icon: "ti-close",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "warning",
      });
      this.popupTemplate = `
        <div class="row">
          <div class="col-12">
            <p><b>Not Ready.</b></p>
            <p><a href="" id="refreshBtn">Click to Refresh</a></p>
          </div>
        </div>
      `;
      this.phoneBtnName = "Offline";
      this.openPopup();
    },
    handleOffline() {
      this.$notify({
        message: "Virtual Phone is offline.",
        icon: "ti-close",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "warning",
      });
      this.popupTemplate = `
         <div class="row">
          <div class="col-12">
            <p><b>Offline</b></p>
          </div>
        </div>
      `;
      this.closePopup();
      this.phoneBtnName = "Offline";
      // console.log("offline");
    },
    handleClosed() {
      // this function does something important

      location.reload();

      // this.$notify({
      //     message: 'Virtual Phone is offline.',
      //     icon: "ti-close",
      //     horizontalAlign: 'center',
      //     verticalAlign: 'top',
      //     type: 'warning'
      // });
      // this.popupTemplate = `
      //   <div class="row">
      //     <div class="col-12">
      //       <p><b>Closed</b></p>
      //     </div>
      //   </div>
      // `;
      // this.phoneBtnName = 'Offline';
      // console.log('closed')
      // this.closePopup();
      // this.initVoiceClient();
    },
    openPopup() {
      $(function () {
        $('[data-toggle="popover"]').popover("show");
      });
    },
    closePopup() {
      $(function () {
        $('[data-toggle="popover"]').popover("hide");
      });
    },
  },
};
</script>
<style lang="scss" >
.popover {
  width: 500px;
  padding: 10px 5px 5px;
  .icon {
    width: 29px;
    height: 29px;
  }
  p {
    text-align: center;
    margin-bottom: 0;
  }
  .dropdown {
    width: 100%;
    .btn {
      width: 100%;
      margin: 0;
    }
    .dropdown-menu {
      width: 100%;
    }
  }
  .iconActions {
    text-align: center;
  }
  .makeCallAction {
    margin-top: 15px;
  }
  .iconActions {
    margin-top: 15px;
    .icon {
      &.red {
        img {
          background: #00d0f5;
        }
      }
      &.green {
        img {
          background: #d929f7;
        }
      }
      img {
        width: 40px;
        cursor: pointer;
        margin: 0 5px;
        filter: invert(100%);
        background: rgb(255, 255, 255);
        padding: 5px;
        border-radius: 200px;
      }
    }
  }
  .home {
    input {
      border: 1px solid rgb(118, 118, 118);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    button {
      margin: 0;
      color: rgb(118, 118, 118);
    }
  }
}
</style>
