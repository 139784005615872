<template>
  <div class="col-12">
    <chart-card title="Case Overview Line Chart"
                sub-title="Group Case by different categories"
                v-if="!isFetchingCases"
                :chart-data="data"
                :chart-options="options"
                footer-text='Last Two weeks (Default)'
    >
      <div class="row" slot="filter">
        <div class="col-12">
          <div class="dropdown menu">
            <button class="btn btn-round btn-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Filter
            </button>
            <div class="dropdown-menu dropdown-menu-left p4">
              <form class="col-12" @submit.prevent>
                <fg-input-dropdown type="text"
                                   label="Group By"
                                   placeholder="e.g Nature"
                                   required
                                   :options="tagOptions"
                                   v-model="tag">
                </fg-input-dropdown>
                <div class="form-group">
                  <label>From</label>
                  <date-picker name="startDate" v-model="startDate" :config="config"></date-picker>
                </div>
                <div class="form-group">
                  <label>To</label>
                  <date-picker name="endDate" v-model="endDate" :config="config"></date-picker>
                </div>
                <p-button type="success"
                          style="margin-left: auto; display: block;"
                          round
                          @click.native.prevent="fetchWithFilter">
                  Submit
                </p-button>
                <br />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!data || data.series.length === 0" slot="empty">No Data Available</div>
    </chart-card>
  </div>
</template>
<script>
import {
  mapState,
  mapActions,
} from 'vuex';
import moment from 'moment';
import Chartist from 'chartist';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
require('chartist-plugin-legend');
import { ChartCard } from "@/components/index";
import { getCounsellor } from '@/utils/auth'
import {oneDayMillis, oneMonthMillis, twoWeeksMillis} from "@/utils/utils";
export default {
  components: {
    ChartCard
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      counsellor: null,
      startDate: null,
      endDate: null,
      tag: 'nature',
      tagOptions: [
        {key: 0, value: "nature", text: "Case Nature"},
        {key: 1, value: "LGA", text: "Local Government Area"},
        {key: 2, value: "callHandlerUuid", text: "Call Handler"},
        {key: 3, value: "perpetratorEmploymentStatus", text: "Perpetrator Employment Status"},
        {key: 4, value: "perpetratorGender", text: "Perpetrator Gender"},
        {key: 5, value: "perpetratorRelationship", text: "Perpetrator Relationship"},
        {key: 6, value: "perpetratorAgeRange", text: "Perpetrator Age Range"},
        {key: 7, value: "survivorAge", text: "Survivor Age"},
        {key: 8, value: "survivorGender", text: "Survivor Gender"},
        {key: 9, value: "survivorMaritalStatus", text: "Survivor Marital Status"},
        {key: 10, value: "survivorEmploymentStatus", text: "Survivor Employment Status"},
        {key: 11, value: "reporterAge", text: "Reporter Age"},
        {key: 12, value: "reporterType", text: "Reporter Type"},
        {key: 13, value: "reporterGender", text: "Reporter Gender"},
        {key: 14, value: "referredBy", text: "Referred By"},
      ],
      config: {
        format: '',
        useCurrent: false,
        showClear: true,
        showClose: true,
      },
      legendNames: [],
      filter: {
        metrics: [
          {
            name: "TotalReportedCases",
            tags: {
              workspaceId: []
            },
            group_by: [
              {
                name: "tag",
                tags: [
                  "nature"
                ]
              }
            ],
            aggregators: [
              {
                name: "sum",
                sampling: {
                  value: "1",
                  unit: "hours"
                },
                align_end_time: true
              }
            ]
          }
        ],
        plugins: [],
        cache_time: 0,
        start_absolute: (new Date().getTime() - twoWeeksMillis),
        end_absolute: (new Date().getTime()),
      },
      data: null,
      options: null,
    };
  },
  computed: {
    ...mapState('analytics', ['cases', 'isFetchingCases']),
    ...mapState("auth", ["counsellorList", "isFetchingCounsellorList"]),
  },
  watch: {
    async cases(newValue) {
      await this.formatGraphData(newValue);
    },
    startDate(newValue) {
      this.filter.start_absolute = new Date(newValue).getTime()
    },
    endDate(newValue) {
      this.filter.end_absolute = new Date(newValue).getTime()
    },
    tag(newValue) {
      this.filter.metrics.map(metric => {
        metric.group_by[0].tags = [newValue]
        return metric
      })
    }
  },
  async mounted() {

  },
  async created() {
    this.counsellor = getCounsellor();
    await this.fetchWithFilter()
  },
  methods: {
    ...mapActions('analytics', [
      'fetchCases',
    ]),
    async fetchWithFilter() {
      if ((parseInt(this.filter.end_absolute, 10) - parseInt(this.filter.start_absolute, 10)) <= oneDayMillis) {
        this.filter.metrics.map(metric => {
          metric.aggregators[0].sampling = {
            value: '1',
            unit: 'hours',
          };
          metric.group_by[0].tags =  [
            this.tag
          ];
          metric.tags.workspaceId = [this.counsellor.workspaceId]
          return metric
        })
      }
      else if ((parseInt(this.filter.end_absolute, 10) - parseInt(this.filter.start_absolute, 10)) >= oneMonthMillis) {
        this.filter.metrics.map(metric => {
          metric.aggregators[0].sampling = {
            value: '1',
            unit: 'months',
          };
          metric.tags.workspaceId = [this.counsellor.workspaceId]
          return metric
        })
      }
      else {
        this.filter.metrics.map(metric => {
          metric.aggregators[0].sampling = {
            value: '1',
            unit: 'days',
          };
          metric.group_by[0].tags =  [
            this.tag
          ];
          metric.tags.workspaceId = [this.counsellor.workspaceId]
          return metric
        })
      }
      const params = this.filter;
      Object.keys(params).forEach((key) => (params[key] === null || params[key] === '') && delete params[key]);
      await this.fetchCases(params);
      //console.log({casssses: JSON.stringify(this.cases)})
    },
    getExpectedTimeStamps (start, end) {
      const expectedTimeStamps = [];
      const startDate          = moment(start);
      const endDate            = moment(end);
      if ((this.filter.end_absolute - this.filter.start_absolute) <= oneDayMillis) {
        while (startDate.isBefore(endDate)) {
          expectedTimeStamps.push(startDate.format('h:mm a'));
          startDate.add(1, 'hours');
        }
        expectedTimeStamps.push(endDate.format('h:mm a'));
      }
      else if ((this.filter.end_absolute - this.filter.start_absolute) >= oneMonthMillis) {
        while (startDate.isBefore(endDate)) {
          expectedTimeStamps.push(startDate.format('MMMM YYYY'));
          startDate.add(1, 'months');
        }
        return expectedTimeStamps;
      }
      else {
        while (startDate.isBefore(endDate)) {
          expectedTimeStamps.push(startDate.format('YYYY-MM-DD'));
          startDate.add(1, 'days');
        }
        expectedTimeStamps.push(endDate.format('YYYY-MM-DD'));
      }
      expectedTimeStamps.shift()
      return expectedTimeStamps;
    },
    async formatGraphData() {
      this.data = {
        labels: [],
        series: []
      }
      this.legendNames = []
      this.options = {
        low: 0,
        showArea: false,
        height: "350px",
        axisX: {
          showGrid: true
        },
        axisY: {
          onlyInteger: true,
          showGrid: true
        },
        lineSmooth: Chartist.Interpolation.simple({
          divisor: 3
        }),
        labelOffset: 70,
        labelDirection: 'explode',
        showLine: true,
        pointRadius: 0.3,
        pointHoverRadius: 0.3,
        plugins: [
          Chartist.plugins.tooltip(),
          ChartistTooltip(),
          Chartist.plugins.legend({
            position: 'bottom',
            legendNames: this.legendNames,
          })
        ]
      }
      const expectedTimeStamps = this.getExpectedTimeStamps(
        this.filter.start_absolute,
        this.filter.end_absolute
      );
      //console.log("EXPECTED FOR CASES", expectedTimeStamps)
      expectedTimeStamps.forEach((timestamp, idx) => {
        if (!this.data.labels.includes(timestamp)) this.data.labels.push(timestamp);
      });
      this.cases.queries.forEach(query => {
        query.results.forEach((result, idx) => {
          switch (result.name) {
            case 'TotalReportedCases':
              const resultTimestamps = result.values.map(value => {
                if ((this.filter.end_absolute - this.filter.start_absolute) <= oneDayMillis) {
                  return moment(value[0]).format('h:mm a')
                }
                else if ((this.filter.end_absolute - this.filter.start_absolute) >= oneMonthMillis) {
                  return moment(value[0]).subtract(1, 'months').format('MMMM YYYY')
                }
                else {
                  return moment(value[0]).format('YYYY-MM-DD')
                }
              });

              if (this.tag === 'callHandlerUuid') {
                if (result.group_by[0].group.callHandlerUuid === '') {
                  this.legendNames.push('Unassigned*')
                } else {
                  const agent = this.counsellorList.find(c => c.uuid === result.group_by[0].group.callHandlerUuid)
                  agent ? this.legendNames.push(agent.name) : this.legendNames.push(result.group_by[0].group.callHandlerUuid)
                }
              } else {
                this.legendNames.push(result.group_by[0].group[this.tag]);
              }

              const resultMap = result.values.reduce((map, value) => {
                if ((this.filter.end_absolute - this.filter.start_absolute) <= oneDayMillis) {
                  map[moment(value[0]).format('h:mm a')] = value[1];
                  return map;
                }
                else if ((this.filter.end_absolute - this.filter.start_absolute) >= oneMonthMillis) {
                  map[moment(value[0]).subtract(1, 'months').format('MMMM YYYY')] = value[1]
                  return map;
                }
                else {
                  map[moment(value[0]).format('YYYY-MM-DD')] = value[1]
                  return map;
                }
              }, {});

              //console.log("RESULT MAP ", JSON.stringify(resultMap))

              const elements = [];
              expectedTimeStamps.forEach((timestamp, idx) => {
                if (resultTimestamps.includes(timestamp)) {
                  elements.push(resultMap[timestamp]);
                } else {
                  elements.push(0);
                }
              });
              this.data.series.push(elements);

              break;
            default:
              break;
          }
        })
        // console.log("LEGEND NAME  ", JSON.stringify(this.legendNames))
        // console.log("DATATATA ", JSON.stringify(this.data))
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.menu.dropdown {
  margin-bottom: 15px
}
</style>
